(function () {
    'use strict';

    // TODO: think about merging those filters
    angular
        .module('mtvApp')
        .filter('orderTypeFilter', orderTypeFilter)
        .filter('orderTypeConverter', orderTypeConverter);

    function orderTypeFilter() {
        return function (orderType) {
            var color;

            function isOffer() {
                return color = 'text-green';
            }

            function isBid() {
                return color = 'text-orange';
            }

            function isBidWanted() {
                return color = 'text-grey';
            }

            var orderTypes = {
                'SITUATION_BID': isBid,
                'BW_BID': isBid,
                'BID_WANTED': isBidWanted,
                'OFFERING': isOffer
            };

            return orderTypes[orderType]();
        }
    }

    function orderTypeConverter() {
        return function (orderType) {
            var type;

            function isOffer() {
                return type = 'Offer';
            }

            function isBid() {
                return type = 'Bid';
            }

            var orderTypes  = {
                'SITUATION_BID': isBid,
                'BW_BID': isBid,
                'OFFERING': isOffer
            };

            return orderTypes[orderType]();
        }
    }
})();
