(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('scrollToActive', scrollToActive);

    function scrollToActive() {
        var directive = {
            restrict: 'A',
            scope: {
                scrollToActive: '<'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            scope.$watch('scrollToActive', scroll, true);

            function scroll(data) {
                if (!data) {
                    return;
                }
                
                scrollToActive(data);
            }

            function scrollToActive(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].active) {
                        var row = element.find('tr');
                        var top = calculateClickedRowHeight(row, i);
                        element.animate({scrollTop: top}, 'slow');
                        return;
                    }
                }
                
                element.animate({scrollTop: 0}, 'slow');

                function calculateClickedRowHeight(row, index) {
                    return index * row[row.length - 1].offsetHeight - element[0].offsetHeight / 2;
                }
            }
        }
    }
})();
