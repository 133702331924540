(function () {
    'use strict';

    var navigationControl = {
        templateUrl: 'app/components/navigation-control/navigation-control.template.html',
        controller: NavigationControlController
    };

    angular
        .module('mtvApp')
        .component('navigationControl', navigationControl);

    NavigationControlController.$inject = ['$state', 'Auth', 'Account', 'Principal', '$uibModal'];

    function NavigationControlController($state, Auth, Account, Principal, $uibModal) {
        var vm = this;
        vm.logout = logout;
        vm.trade = trade;
        vm.review = review;
        vm.userManagement = userManagement;
        vm.changePassword = changePassword;

        vm.account = Account.get();

        function trade() {
            $state.go('standard.trade-mode.home');
        }

        function review() {
            Principal.hasAuthority('ROLE_REVIEW').then(function(isReviewMode){
                isReviewMode ? $state.go('standard.review-mode.user', {}, {reload: true})
                    : $state.go('standard.trader-review-mode.date', {}, {reload: true});
            });
        }

        function userManagement() {
            $state.go('user-management');
        }

        function changePassword() {
            $uibModal.open({
                templateUrl: 'app/components/change-password/change-password.template.html',
                controller: 'ChangePasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: 'vertical-modal'
            })
        }

        function logout() {
            Auth.logout();
            $state.go('login');
        }
    }
})();
