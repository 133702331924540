(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('TradeSide', TradeSide);

    function TradeSide() {

        return {
            getValues: function () {
                return ["BUY", "SELL"];
            }
        }
    }
})();
