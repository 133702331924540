(function () {
    'use strict';

    var notCorporateDataReport = {
        templateUrl: 'app/components/not-corporate-data/not-corporate-data-report.template.html',
        controller: NonCorporateDataReportController,
        bindings: {
            report: '<',
            bestExecutionReview: '<',
            approvedHash: '<',
            marketType: '<',
            isReviewMode: '<',
            viewType: '<',
            rtrsDataType: '<',
            rtrsPartyType: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('notCorporateDataReport', notCorporateDataReport);

    function NonCorporateDataReportController() {
        var vm = this;

        vm.tradeTypeItems = [
            {value: 'All', displayName: 'All'},
            {value: 'SALE', displayName: 'Customer Bought'},
            {value: 'BUY', displayName: 'Customer Sold'},
            {value: 'INT_DEALER', displayName: 'Inter-dealer'}
        ];

        vm.$onInit = function () {
            if (vm.rtrsDataType) {
                vm.rtrsDataType = vm.tradeTypeItems.filter(function (e) {
                    return e.value === vm.rtrsDataType;
                })[0].displayName;
            }
        };
    }
})();
