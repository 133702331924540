(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('StandardController', StandardController);

    StandardController.$inject = ['$scope', 'Principal'];

    function StandardController($scope, Principal) {
        var vm = this;

        vm.account = null;
        vm.currentDate = new Date();

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });

            //move to directive if used more than once
            var sidebar = angular.element('#sidebar');
            $scope.$watch(function () {
                return angular.element('.side-panel-container').height();
            }, function(value) {
                sidebar.css('min-height', value + 190);
            });
        }
    }
})();
