(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('ChangePasswordController', ChangePasswordController);

    ChangePasswordController.$inject = ['$http', '$uibModalInstance', 'AlertService'];

    function ChangePasswordController($http, $uibModalInstance, AlertService) {
        var vm = this;

        vm.close = close;
        vm.changePassword = changePassword;
        vm.passwordChanged = false;
        vm.wrongOldPassword = false;

        function close() {
            $uibModalInstance.close();
        }

        function changePassword() {
            vm.wrongOldPassword = false;
            $http.post('api/account/change-password', {
                newPassword: vm.newPassword,
                oldPassword: vm.oldPassword
            }).then(function success() {
                vm.passwordChanged = true;
            }, function error(response) {
                if (response.status === 404) {
                    vm.wrongOldPassword = true;
                } else {
                    AlertService.error("Cannot change password");
                }
            });
        }
    }
})
();
