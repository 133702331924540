(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('clearButton', ['$compile', clearButton]);

    function clearButton($compile) {
        var directive = {
            restrict: 'A',
            transclude: 'element',
            scope: {
                top: '@clearButtonTop',
                right: '@clearButtonRight'
            },
            link: function (scope, element, attrs, ctrl, transclude) {
                var clearButtonClass = new Date().getTime();
                var template = angular.element(
                    '<div class=\"input-with-clear-button-'+ clearButtonClass+'\">' +
                    '<div class=\"ng-hide glyphicon glyphicon-remove\"</div>' +
                    '</div>'
                );

                var newElement = $compile(template)(scope);
                transclude(function (elementWithDirective, scope) {
                    elementWithDirective.removeAttr(attrs.$attr.clearButton);
                    newElement.prepend($compile(elementWithDirective)(scope));
                });
                element.replaceWith(newElement);

                if(newElement.find('input').prop('disabled')) {
                    return;
                }

                var field = angular.element('.input-with-clear-button-' + clearButtonClass)
                    .closest('.form-group');
                var input = field.find('input');
                var clearButton = field.find('.glyphicon-remove');
                clearButton.css('color', '#6e92b4');
                clearButton.css('cursor', 'pointer');
                clearButton.css('font-size', '13px');
                clearButton.css('right', (scope.right || 0) + 'px');
                clearButton.css('position', 'absolute');
                clearButton.css('top', (scope.top || 35) + 'px');

                field.on('mouseover keyup change', function () {
                    if (input.val()) {
                        clearButton.removeClass('ng-hide');
                    } else {
                        clearButton.addClass('ng-hide');
                    }
                });

                field.on('mouseout focusout', function () {
                    clearButton.addClass('ng-hide');
                });

                clearButton.on('mousedown', function (e) {
                    //is it left mouse button down
                    if(e.which === 1) {
                        input.val('').trigger('change');
                    }
                });
            }
        };

        return directive;
    }
})();
