(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('TraderCommentsModalController', TraderCommentsModalController);

    TraderCommentsModalController.$inject = ['$scope', '$state', '$uibModalInstance', 'Comments', 'entity'];

    function TraderCommentsModalController($scope, $state, $uibModalInstance, Comments, entity) {
        var vm = this;

        vm.post = post;
        vm.reset = reset;
        vm.close = close;

        init();

        function init() {
            vm.error = '';
            vm.comment = '';
        }

        function post() {
            vm.error = '';
            $scope.traderCommentForm.comment.$dirty = true;

            if (!$scope.traderCommentForm.$valid) {
                return;
            }

            Comments.save({hash: entity.hash}, vm.comment, onSaveSuccess, onSaveError);
        }

        function reset() {
            init();
            $scope.traderCommentForm.$setPristine();
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError() {
            vm.error = 'Can not post comment. Please try again';
        }
    }
})();
