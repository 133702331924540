(function() {
    'use strict';

    angular
        .module('mtvApp')
        .filter('replaceEmptyNullValuesFilter', replaceEmptyNullValuesFilter);

    function replaceEmptyNullValuesFilter() {
        return function(input) {
            if ((!input && input != 0) || input.toString().trim().length === 0) {
                return '-';
            }

            return input;
        }
    }
})();
