(function () {
    'use strict';

    var customSelectedFilter = {
        templateUrl: 'app/components/common/custom-selected-filter.template.html',
        controller: customSelectedFilterController,
        bindings: {
            selectedValue: '<',
            label: '@'
        }
    };

    angular
        .module('mtvApp')
        .component('customSelectedFilter', customSelectedFilter);

    function customSelectedFilterController() {
        var vm = this;

        vm.$onInit = function() {
            if (vm.label) {
                vm.completeLabel = vm.label + ":";
            }
            if (!vm.selectedValue){
                vm.selectedValue = "All";
            }
        };
    }
})();
