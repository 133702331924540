(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('FinraFeedType', FinraFeedType);

    FinraFeedType.$inject = ['$resource'];

    function FinraFeedType($resource) {
        function getValues() {
            return {
                'review-mode': $resource('api/review-mode/feed-type', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                }),
                'trader-review-mode': $resource('api/review-mode/trader/feed-type', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                })
            };
        }

        return {
            getValues: getValues()
        };
    }
})();
