(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('CopyrightController', CopyrightController);

    CopyrightController.$inject = ['$scope', '$window', '$uibModalInstance', 'Account', 'Principal', 'Contact', 'AlertService'];

    function CopyrightController($scope, $window, $uibModalInstance, Account, Principal, Contact, AlertService) {
        var vm = this;

        vm.init = init;
        vm.close = close;
        vm.reset = reset;
        vm.send = send;
        vm.maxEmailLength = 100;
        vm.validateEmailLength = validateEmailLength;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.alerts = AlertService.get();

        init();

        function init() {
            if (!vm.user || !vm.user.email || !vm.user.firstName || !vm.user.lastName) {
                vm.user = Account.get();
            }
            vm.title = null;
            vm.description = null;
        }

        function close() {
            $uibModalInstance.close();
        }

        function reset() {
            init();
            $scope.contactForm.$setPristine();
        }

        function send() {
            $scope.contactForm.title.$dirty = true;
            $scope.contactForm.description.$dirty = true;
            $scope.contactForm.email.$dirty = true;

            if (!$scope.contactForm.$valid || validateEmailLength()) {
                return;
            }
            var userName;
            if (vm.user.firstName && vm.user.lastName) {
                userName = vm.user.firstName + ' ' + vm.user.lastName;
            }
            Contact.post({
                'title': vm.title,
                'description': vm.description,
                'userName': userName,
                'email': vm.user.email,
                'screenWidth': $window.screen.width,
                'screenHeight': $window.screen.height
            }, function () {
                $uibModalInstance.close();
                vm.alerts.push(
                    AlertService.add({
                            type: 'success',
                            msg: 'Your request was successfully sent.',
                            timeout: 4000,
                            scoped: true
                        }
                    )
                );
            });
        }

        function validateEmailLength(){
            if(vm.user && vm.user.email){
                return vm.user.email.length > vm.maxEmailLength;
            }
            return false;
        }
    }
})
();
