(function() {
    'use strict';

    angular
        .module('mtvApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', 'Auth', 'Principal'];

    function LoginController ($rootScope, $state, Auth, Principal) {
        var vm = this;

        vm.currentDate = new Date();

        if (Principal.isAuthenticated()) {
            $state.go('standard.trade-mode.home');
        }

        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.customErrorMessage = $state.params.customErrorMessage;

        function login (event) {
            event.preventDefault();
            vm.customErrorMessage = null;
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                } else {
                    $state.go('standard.trade-mode.home');
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function requestResetPassword () {
            $state.go('requestReset');
        }
    }
})();
