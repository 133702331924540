(function () {
    'use strict';

    var comparablesDetails = {
        templateUrl: 'app/components/comparables/comparables-details.template.html',
        controller: ComparablesDetailsController,
        bindings: {
            data: '=',
            marketType: '<',
            isApproved: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('comparablesDetails', comparablesDetails);

    ComparablesDetailsController.$inject = ['$attrs'];

    function ComparablesDetailsController($attrs) {
        var readOnly = $attrs.readOnly !== undefined;

        var vm = this;
        vm.select = select;
        vm.bestBidOffer = bestBidOffer;
        vm.isFiltered = isFiltered;

        function select(item) {
            if(readOnly) {
                return;
            }

            vm.data.comparables.forEach(function(d) {
                d.active = false;
            });
            item.active = true;
        }

        function isFiltered(item) {
            return vm.isApproved
                ? item.filtered == null
                : item.filtered;
        }

        function bestBidOffer(item) {
            if(!item.marketDataDetails){
                return null;
            }
            return bestFunction(item.marketDataDetails)[vm.marketType];
        }

        function bestFunction(item) {
            return {
                Bid: item['highBid'],
                Offering: item['lowOffering']
            }
        }
    }
})();
