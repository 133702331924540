(function () {
    'use strict';

    var reportInfo = {
        templateUrl: 'app/components/report-info/report-info.template.html',
        controller: ReportInfoController,
        bindings: {
            report: '='
        }
    };

    angular
        .module('mtvApp')
        .component('reportInfo', reportInfo);

    ReportInfoController.$inject = ['$rootScope', '$state', '$stateParams', 'Snapshot', 'Cusip', 'Principal', '$location'];

    function ReportInfoController($rootScope, $state, $stateParams, Snapshot, Cusip, Principal, $location) {
        var vm = this;

        vm.back = back;
        vm.isReviewMode = $state.current.name === 'read-only.report';
        vm.isView = Cusip.isView;
        vm.getTextForOptional = getTextForOptional;
        vm.getTextForFlag = getTextForFlag;

        init();

        function init() {
            vm.approvedHash = $stateParams['approvedHash'];
            vm.prevStateParams = $stateParams['prevStateParams'];

            if (vm.approvedHash) {
                $rootScope.$broadcast('mtvApp:approve', {approvedHash: vm.approvedHash});
                Snapshot.get({hash: vm.approvedHash}, function (result) {
                    vm.report = result;
                    processReport();
                });
                return;
            }

            function processReport() {
                if(vm.report.snapshot.finraData !== null
                    && vm.report.snapshot.finraData !== undefined
                    && vm.report.snapshot.finraData.data !== null
                    && vm.report.snapshot.finraData.data !== undefined) {
                    vm.report.snapshot.finraData.data = vm.report.snapshot.finraData.data.slice(0, 500);
                }

                //TODO: change logic (view not based on data)
                vm.viewType = vm.report.snapshot.rtrsData === null || vm.report.snapshot.rtrsData === undefined
                    ? 'CORPORATE'
                    : 'MUNICIPAL';
                mergeToleranceChecks(vm.report.forwardChecks);
                vm.report.snapshot.securityDetails.cusip = vm.report.snapshot.securityDetails.cusip || vm.report.info.cusip;
                prepareData();
            }

            function prepareData() {
                vm.marketType = marketTypeBySide(vm.report.info.tradeSide);
                vm.bestExecutionReview = prepareBestExecutionReview(vm.report.snapshot.bestExecutionReviewData);
                if(vm.isView.municipal(vm.viewType)) {
                    vm.rtrsDataType = vm.report.snapshot.rtrsData.rtrsDataType;
                    vm.rtrsPartyType = vm.report.snapshot.rtrsData.rtrsPartyType;
                }
                //TODO: change logic (view not based on data)
                if(vm.isView.corporate(vm.viewType) && vm.report.snapshot.finraData !== null) {
                    vm.reportingPartyType = vm.report.snapshot.finraData.reportingPartyType;
                    vm.contraPartyType = vm.report.snapshot.finraData.contraPartyType;
                }
            }

            if($stateParams['key'] && $stateParams['cusip']){
                $state.go('direct-access.trade-mode.search.approve', {
                    cusip: $stateParams.cusip,
                    price: $stateParams.price,
                    size: $stateParams.size,
                    tradeSide: $stateParams.tradeSide,
                    tradeId: $stateParams.tradeId,
                    key: $stateParams.key
                });
                return;
            }

            var cusip = $stateParams['cusip'];

            if(cusip){
                $state.go('standard.trade-mode.search.approve', {cusip: cusip});
                return;
            }

            $state.go('standard.trade-mode.home');

            function prepareBestExecutionReview(bestExecutionReview) {
                bestExecutionReview = bestExecutionReview || {};
                bestExecutionReview.bestExecutionStatistics.snapshotTradeStatistics = vm.report.firmWideStats;

                var closestPeer = {
                    marketDataClosest: bestExecutionReview.marketDataClosest,
                    tradeHistoryClosest: bestExecutionReview.tradeHistoryClosest
                };
                return angular.extend({}, {closestPeer: closestPeer},
                    {bestExecutionStatistics: bestExecutionReview.bestExecutionStatistics},
                    {toleranceChecks: bestExecutionReview.toleranceChecks});
            }
        }

        function back() {
            $state.go($rootScope.fromState.name, vm.prevStateParams);
        }

        function marketTypeBySide(side) {
            var types =  {
                buy: 'Offering',
                sell: 'Bid'
            };

            return types[side.toLowerCase()];
        }

        function getTextForOptional(optionalField) {
            return optionalField ? optionalField : '-';
        }

        function getTextForFlag(flagField) {
            return flagField ? '\u2714' : '-';
        }

        function mergeToleranceChecks(forwardChecks) {
            if (!forwardChecks) {
                return;
            }
            forwardChecks.forEach(function (forwardCheck) {
                // TODO rename fields to simplify
                vm.report.snapshot.bestExecutionReviewData.toleranceChecks.push({ checked: forwardCheck.checkedFlag, rule: forwardCheck.ruleText });
            });
        }
    }
})();
