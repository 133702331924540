(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('scrollToSection', scrollToSection);

    function scrollToSection() {
        var directive = {
            restrict: 'A',
            scope: {
                scrollToSection: '<'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope) {
            var container = angular.element('.content-main-holder');

            scope.$watch('scrollToSection', scroll, true);

            function scroll(value, oldValue){
                if(value === oldValue) {
                    return
                }
                var element = angular.element('.' + value);
                if(element.length !== 0) {
                    container.scrollTo(element, 48, 1000);
                }
            }
        }
    }
})();
