(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('daLayout', daLayout);

    daLayout.$inject = ['Principal'];

    function daLayout(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            if (Principal.getIdentity().authorities.indexOf("ROLE_DIRECT_ACCESS") === -1
                || Principal.getIdentity().authorities.length !== 1) {
                return;
            }

            element.addClass(attrs.daClass);
            angular.forEach(angular.fromJson(attrs.daAttrs), function (key, value) {
                element.attr(value, key);
            });
        }
    }
})();

