(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('finraFeedTypeFilter', finraFeedTypeFilter);

    function finraFeedTypeFilter() {
        return function (value) {
            if (!value) {
                return '';
            }
            var viewTypes = {
                'UNKNOWN': 'Municipal',
                'BTDS': 'Corporate',
                'BTDS144': 'Corporate 144A',
                'ATDS': 'Agency',
                'SPDS': 'Securitized',
                'SPDS144': 'Securitized 144A'
            };

            return viewTypes[value];
        }
    }
})();
