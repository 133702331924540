(function () {
    'use strict';

    var tradeTable = {
        templateUrl: ['$attrs', function ($attrs) {
            var templateName = $attrs.groupBy || "all-reports";
            return 'app/standard/review-mode/components/trade-table/trade-table-' + templateName.toLowerCase() + '.template.html';
        }],
        controller: TradeTableController,
        bindings: {}
    };

    angular
        .module('mtvApp')
        .component('tradeTable', tradeTable);

    TradeTableController.$inject = ['$state', '$attrs', '$filter', 'Review', 'Principal'];

    function TradeTableController($state, $attrs, $filter, Review, Principal) {
        var vm = this;

        vm.loadSummary = loadSummary;
        vm.loadReports = loadReports;

        vm.toggleDetails = toggleDetails;
        vm.showSnapshot = showSnapshot;
        vm.isActiveRow = isActiveRow;
        vm.showPagination = showPagination;
        vm.hasPermission = hasPermission;
        vm.reviewType = $state.current.name.split('.')[1];
        vm.unreadOnlyFlag = $state.params.unreadOnlyFlag === 'true';
        vm.outOfToleranceCheckFlag = $state.params.outOfToleranceCheckFlag === 'true';
        vm.bulkUploadFlag = $state.params.bulkUploadFlag === 'true';
        vm.commentsFlag = $state.params.commentsFlag === 'true';
        vm.userCommentsFlag = $state.params.userCommentsFlag === 'true';
        vm.correctionFlag = $state.params.correctionFlag === 'true';
        vm.counterPartyFlags = $state.params.counterPartyFlags;
        vm.finraFeedFlags = $state.params.finraFeedFlags;
        vm.tradeSideFlags = $state.params.tradeSideFlags;
        vm.activePosition = $state.params.activePosition;

        setPermissions();

        vm.pagination = {
            outer: {
                totalCount: 0,
                pageSize: 10,
                page: preparePageNumber($state.params.outerPage)
            },
            inner: {
                totalCount: 0,
                pageSize: 5,
                page: preparePageNumber($state.params.innerPage)
            }
        };

        function init() {
            if ($attrs.groupBy) {
                loadSummary();
            } else {
                loadReports();
            }
            //cleanup of parameters from previous "Back to Review"-transition
            $state.params.activePosition = undefined;
            $state.params.outerPage = undefined;
            $state.params.innerPage = undefined;
        }

        init();

        function loadSummary() {
            vm.params = {
                page: vm.pagination.outer.page - 1,
                size: vm.pagination.outer.pageSize,
                groupBy: $attrs.groupBy,
                dateFrom: $state.params.from,
                dateTo: $state.params.to,
                traderName: $state.params.trader,
                cusip: $state.params.cusip,
                unreadOnlyFlag: $state.params.unreadOnlyFlag,
                outOfToleranceCheckFlag: $state.params.outOfToleranceCheckFlag,
                insufficientDataFlag: $state.params.insufficientDataFlag,
                bulkUploadFlag: $state.params.bulkUploadFlag,
                commentsFlag: $state.params.commentsFlag,
                userCommentsFlag: $state.params.userCommentsFlag,
                correctionFlag: $state.params.correctionFlag,
                counterPartyFlags: $state.params.counterPartyFlags,
                finraFeedFlags: $state.params.finraFeedFlags,
                tradeSideFlags: $state.params.tradeSideFlags
            };

            getSummary();
            collapseReports();
        }

        function getSummary() {
            Review.getSummary[vm.reviewType].query(vm.params, function (result, headers) {
                vm.pagination.outer.totalCount = headers('X-Total-Count');
                vm.summaryItems = result;
                if (vm.activePosition >= 0 && vm.activePosition < vm.pagination.outer.pageSize) {
                    loadReports(vm.activePosition);
                }
            });
        }

        function loadReports($index) {
            var page = vm.activePosition === $index
                ? vm.pagination[getPaginationType($index)].page - 1
                : 0;

            var params = {
                page: page,
                size: vm.pagination[getPaginationType($index)].pageSize
            };

            angular.extend(params, {
                cusip: $state.params.cusip,
                unreadOnlyFlag: $state.params.unreadOnlyFlag,
                outOfToleranceCheckFlag: $state.params.outOfToleranceCheckFlag,
                insufficientDataFlag: $state.params.insufficientDataFlag,
                bulkUploadFlag: $state.params.bulkUploadFlag,
                commentsFlag: $state.params.commentsFlag,
                userCommentsFlag: $state.params.userCommentsFlag,
                correctionFlag: $state.params.correctionFlag,
                counterPartyFlags: $state.params.counterPartyFlags,
                finraFeedFlags: $state.params.finraFeedFlags,
                tradeSideFlags: $state.params.tradeSideFlags
            });

            if ($attrs.groupBy === 'USER') {
                angular.extend(params, {
                    traderName: vm.summaryItems[$index]['email'],
                    dateFrom: $state.params.from,
                    dateTo: $state.params.to
                });
            }

            if ($attrs.groupBy === 'DATE') {
                angular.extend(params, {
                    traderName: $state.params.trader,
                    dateFrom: $filter('mtvDate')(vm.summaryItems[$index]['tradeDate'], 'YYYY-MM-DD'),
                    dateTo: $filter('mtvDate')(vm.summaryItems[$index]['tradeDate'], 'YYYY-MM-DD')
                });
            }

            if (!$attrs.groupBy) {
                angular.extend(params, {
                    traderName: $state.params.trader,
                    dateFrom: $state.params.from,
                    dateTo: $state.params.to
                });
            }

            Review.getDetails[vm.reviewType].query(params, function (result, headers) {
                if (vm.activePosition !== $index) {
                    vm.pagination[getPaginationType($index)].page = page;
                    vm.activePosition = null;
                }
                vm.pagination[getPaginationType($index)].totalCount = headers('X-Total-Count');
                vm.reports = result;
                vm.activePosition = $index;
            });

            function getPaginationType($index) {
                return $index || $index === 0
                    ? 'inner'
                    : 'outer';
            }

            vm.pdfParams = params;
        }

        function showSnapshot(item) {
            $state.go('read-only.report', {
                approvedHash: item.hash,
                prevStateParams: angular.extend($state.params, {
                    activePosition: vm.activePosition,
                    outerPage: vm.pagination.outer.page,
                    innerPage: vm.pagination.inner.page
                })
            });
        }

        function toggleDetails($index) {
            if (isActiveRow($index)) {
                vm.activePosition = -1;
                return;
            }
            loadReports($index);
        }

        function isActiveRow($index) {
            return vm.activePosition === $index;
        }

        function showPagination(pagination) {
            return pagination.totalCount > pagination.pageSize;
        }

        function setPermissions() {
            Principal.getCompanyPermissions().then(function (result) {
                vm.permissions = result;
            });
        }

        function hasPermission(permission) {
            return vm.permissions[permission];
        }

        function collapseReports() {
            if ($state.params.activePosition === undefined) {
                vm.activePosition = -1;
            }
        }

        function preparePageNumber(value) {
            return value === undefined || value < 1
                ? 1
                : value;
        }
    }
})();
