(function () {
    'use strict';

    var comparablesAverageDetails = {
        templateUrl: ['$element', '$attrs', function ($element, $attrs) {
            var state = $attrs.readOnly !== undefined ? 'read-only.' : '';
            return 'app/components/comparables/comparables-average-details.' + state + 'template.html';
        }],
        controller: ComparablesAverageDetailsController,
        bindings: {
            data: '<',
            marketType: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('comparablesAverageDetails', comparablesAverageDetails);

    ComparablesAverageDetailsController.$inject = ['$scope'];

    function ComparablesAverageDetailsController($scope) {
        var vm = this;

        vm.average = {
            bidOffer: {
                all: 0,
                filtered: 0,
                selected: 0
            },
            trade: {
                all: 0,
                filtered: 0,
                selected: 0
            }
        };

        $scope.$watch('$ctrl.data', function (newValue, oldValue) {
            if (!newValue) {
                return;
            }
            vm.average = calculateAverage();
        }, true);

        function calculateAverage() {
            return {
                bidOffer: {
                    all: all('lastOpenTradePrice'),
                    filtered: filtered('lastOpenTradePrice'),
                    selected: selected('lastOpenTradePrice')
                },
                trade: {
                    all: all('lastClosedTradePrice'),
                    filtered: filtered('lastClosedTradePrice'),
                    selected: selected('lastClosedTradePrice')
                }
            }
        }

        function all(fieldName) {
            var values = mapToMarketDataDetailsField(vm.data.comparables, fieldName);
            return average(values);
        }

        function filtered(fieldName) {
            var items = vm.data.comparables.filter(function (item) {
                return item.filtered;
            });
            var values = mapToMarketDataDetailsField(items, fieldName);
            return average(values);
        }

        function selected(fieldName) {
            var items = vm.data.comparables.filter(function (item) {
                return item.filtered && item.selected;
            });
            var values = mapToMarketDataDetailsField(items, fieldName);
            return average(values);
        }

        function mapToMarketDataDetailsField(items, fieldName) {
            return items.map(function (item) {
                return item['marketDataDetails'];
            }).filter(function (val) {
                return val;
            }).map(function (item) {
                return item[fieldName];
            }).filter(function (val) {
                return val;
            }).map(function (item) {
                return item['amount'];
            });
        }

        function average(values) {
            if (values.length === 0) {
                return 0.0
            }
            return ss.mean(values);
        }
    }
})();
