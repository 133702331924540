(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('NotesAndFlagsController', NotesAndFlagsController);

    NotesAndFlagsController.$inject = ['$state', '$stateParams', '$uibModalInstance', 'Snapshot', 'approveEntity', 'viewType', '$filter'];

    function NotesAndFlagsController($state, $stateParams, $uibModalInstance, Snapshot, approveEntity, viewType, $filter) {
        var vm = this;

        vm.reset = reset;
        vm.approve = approve;
        vm.close = close;

        init();

        function init() {
            vm.viewType = viewType;
            vm.tradeId = $filter("limitTo")($stateParams.tradeId, 20);
            vm.comments = '';
            vm.checkedFlagIds = [];
        }

        function reset() {
            init();
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function approve() {
            var obj = angular.extend(approveEntity, {
                flags: vm.checkedFlagIds,
                tradeId: vm.tradeId,
                comments: vm.comments || null
            });

            Snapshot.save(obj, onAddSnapshotSuccess, onFailure);

            function onAddSnapshotSuccess(res) {
                $uibModalInstance.close({
                    hash: res.hash
                });
            }

            function onFailure() {
                close();
            }
        }
    }
})();
