(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('priceYield', priceYield);

    priceYield.$inject = ['$filter'];

    function priceYield($filter) {
        return function (item, property) {
            var propertyPrice = item.marketDataDetails[property + 'Price'];
            var propertyYield = item.marketDataDetails[property + 'Yield'];

            if (!((propertyPrice && propertyPrice.amount) || propertyYield)) {
                return '-';
            }

            return $filter('replaceEmptyNullValuesFilter')($filter('currency')(propertyPrice.amount, propertyPrice.symbol, 3))
                    + ' / ' + $filter('replaceEmptyNullValuesFilter')(($filter('number')(propertyYield, 3)));
        }
    }
})();
