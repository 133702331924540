(function () {
    'use strict';

    var copyright = {
        templateUrl: 'app/components/copyright/copyright.template.html',
        controller: CopyrightComponent
    };

    angular
        .module('mtvApp')
        .component('copyright', copyright);

    CopyrightComponent.$inject = ['$uibModal'];

    function CopyrightComponent($uibModal) {
        var vm = this;

        vm.openModal = openModal;

        function openModal(template, className) {
            $uibModal.open({
                templateUrl: 'app/components/copyright/'+ template +'.template.html',
                controller: 'CopyrightController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: className
            })
        }
    }
})();
