(function () {
    'use strict';

    var bestExecutionReview = {
        templateUrl: 'app/components/best-execution-review/best-execution-review.template.html',
        controller: BestExecutionReviewController,
        bindings: {
            dpSettings: '<',
            bestExecutionStatistics: '<',
            closestPeer: '<',
            toleranceChecks: '<',
            isModal: '<'
        }
    };

    var finraBestExecutionReview = {
        templateUrl: 'app/components/best-execution-review/finra-best-execution-review.template.html',
        controller: BestExecutionReviewController,
        bindings: {
            dpSettings: '<',
            bestExecutionStatistics: '<',
            toleranceChecks: '<',
            isModal: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('bestExecutionReview', bestExecutionReview)
        .component('finraBestExecutionReview', finraBestExecutionReview);

    BestExecutionReviewController.$inject = ['Cusip'];

    function BestExecutionReviewController(Cusip) {
        var vm = this;

        vm.shouldShowToleranceChecks = shouldShowToleranceChecks;

        if (vm.dpSettings) {
            vm.marketDataPermission = Cusip.getDataPermission.marketData(vm.dpSettings).value;
            vm.tradeHistoryPermission = Cusip.getDataPermission.tradeHistory(vm.dpSettings).value;
            vm.snapshotStatsPermission = Cusip.getDataPermission.snapshotStats(vm.dpSettings).value;
            vm.comparablesPermission = Cusip.getDataPermission.comparables(vm.dpSettings).value;
        }

        function shouldShowToleranceChecks() {
            return vm.toleranceChecks && vm.toleranceChecks.length !== 0;
        }
    }
})();
