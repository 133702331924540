(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.trade-mode.search.approved.report', {
            url: '/approved?cusip,key',
            data: {
                authorities: ['ROLE_USER', 'ROLE_DIRECT_ACCESS']
            },
            views: {
                'read-only-container': {
                    template:'<report-info report="vm.report"></report-info>'
                },
                'header-container': {
                    template: '<header state="read-only" report="vm.report"></header>'
                }
            }
        });
    }
})();
