(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('ApprovedController', ApprovedController);

    ApprovedController.$inject = [];

    function ApprovedController() {
        var vm = this;
    }
})();
