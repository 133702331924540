(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('comparablesChart', comparablesChart);

    comparablesChart.$inject = ['$compile', '$filter', 'ChartUtil'];

    function comparablesChart($compile, $filter, ChartUtil) {
        var directive = {
            replace: true,
            restrict: 'E',
            templateUrl: 'app/components/comparables/comparables-chart.template.html',
            scope: {
                data: '=',
                marketType: '<',
                price: '<'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var readOnly = attrs.readOnly !== undefined;
            var priceLineShouldBeShown, price;

            var circleColor = {
                green: '#2bbd2b',
                orange: '#ff8227',
                blue: '#3eb3e8'
            };

            var typeColor = {
                Bid: circleColor.orange,
                Offering: circleColor.green
            };

            scope.chartDataType = chartDataType;
            scope.chartDataField = chartDataField;
            scope.isDataType = isDataType;
            scope.isMarketType = isMarketType;
            scope.options = {
                dataTypes: [],
                dataField: null
            };
            scope.showControls = attrs.showControls !== undefined;

            var dataFields = {
                yield: 'Yield to Maturity Date',
                price: 'Price to Maturity Date'
            };

            chartDataType('all');

            function dataTypes(typeStatus) {
                var types = {
                    open: ['lastOpenTrade'],
                    closed: ['lastClosedTrade'],
                    all: ['lastOpenTrade', 'lastClosedTrade']
                };

                return types[typeStatus];
            }

            function chartDataType(tradeStatus) {
                scope.options.dataTypes = dataTypes(tradeStatus);
            }

            function isDataType(tradeStatus) {
                return angular.equals(scope.options.dataTypes, dataTypes(tradeStatus));
            }

            function isMarketType(type) {
                return scope.marketType === type;
            }

            function chartDataField(field, label) {
                scope.options.dataField = field;
                scope.chartDataLabel = label;
                angular.element('#chart-label-type').text(label);
            }

            var margin = {top: 60, right: 50, bottom: 50, left: 40},
                outerWidth = 600,
                outerHeight = 380,
                width = outerWidth - margin.left - margin.right,
                height = outerHeight - margin.top - margin.bottom;

            var svg = d3.select('#comparables-scatter')
                .append('svg')
                .attr('width', outerWidth)
                .attr('height', outerHeight)
                .append('g')
                .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

            function prepareData(data, options) {
                var chartData = [];

                angular.forEach(options.dataTypes, function (dataType) {
                    angular.forEach(data, function (comparable, index) {
                        if (!comparable.filtered && scope.showControls) {
                            return;
                        }

                        if(!comparable.securityDetails.maturityDate) {
                            return;
                        }

                        var priceData = comparable['marketDataDetails'][dataType + 'Price'];
                        var yieldData = comparable['marketDataDetails'][dataType + 'Yield'];

                        chartData.push({
                            cusip: comparable.cusip,
                            price: priceData ? priceData.amount : null,
                            yield: yieldData,
                            weight: comparable.weight,
                            maturityDate: moment($filter('mtvDate')(comparable.securityDetails.maturityDate, 'YYYY-MM-DD')).toDate(),
                            color: getColorByMarketType(dataType),
                            index: index,
                            active: comparable.active
                        });
                    });

                });

                return $filter('orderBy')(chartData.filter(function (item) {
                    return item[scope.options.dataField];
                }), function (d) {
                    return d.active;
                });
            }

            function getColorByMarketType(dataType) {
                var colors = {
                    Offering: {
                        lastOpenTrade: circleColor.green,
                        lastClosedTrade: circleColor.blue
                    },
                    Bid: {
                        lastOpenTrade: circleColor.orange,
                        lastClosedTrade: circleColor.blue
                    }
                };

                return colors[scope.marketType][dataType];
            }

            scope.$watch('data', drawOnDataChange, true);
            scope.$watch('options', drawOnOptionsChange, true);

            function drawOnDataChange(newVal) {
                if (!newVal) {
                    return;
                }
                newVal.type = newVal.type || 'yield';
                svg.selectAll('*').remove();
                chartDataField(newVal.type, dataFields[newVal.type]);
                var graphData = prepareData(newVal.comparables, scope.options);
                var parsedPrice = parsePrice(scope.price);
                priceLineShouldBeShown = priceIsNumber(parsedPrice) && newVal.comparables.length > 0;
                price = priceLineShouldBeShown
                    ? parsedPrice
                    : null;
                render(graphData, scope.options.dataField);
                $compile(element)(scope);
            }

            function priceIsNumber(price) {
                return price !== null
                    && !isNaN(price)
                    && angular.isNumber(price);
            }

            function parsePrice(value) {
                return value !== null
                    ? parseFloat(value.amount)
                    : null;
            }

            function drawOnOptionsChange(newVal) {
                if (!newVal || !scope.data) {
                    return;
                }

                scope.data.type = newVal.dataField;
                svg.selectAll('*').remove();
                angular.element('.data-type-ctrls button').removeClass('active');
                var graphData = prepareData(scope.data.comparables, newVal);
                var parsedPrice = parsePrice(scope.price);
                priceLineShouldBeShown = priceIsNumber(parsedPrice) && scope.data.comparables.length > 0;
                price = priceLineShouldBeShown
                    ? parsedPrice
                    : null;
                render(graphData, scope.options.dataField);
                $compile(element)(scope);
            }

            function render(data, dataField) {
                var xCat = 'maturityDate',
                    yCat = dataField,
                    rCat = 'weight';

                var xAxisDomain = normalizeDomain({type: 'DATE', data: data, field: xCat});
                var yAxisDomain = normalizeDomain({data: data, field: yCat});

                function yAxisContainsValues () {
                    return  !isNaN(yAxisDomain[0])
                        && !isNaN(yAxisDomain[1]);
                }

                priceLineShouldBeShown = priceLineShouldBeShown && yAxisContainsValues();

                if (scope.options.dataField === 'price' && priceLineShouldBeShown) {
                    if (yAxisDomain[0] > price) {
                        yAxisDomain[0] = price - getAdditionalTickSize(yAxisDomain[1], price)
                    }
                    if (yAxisDomain[1] < price) {
                        yAxisDomain[1] = price + getAdditionalTickSize(yAxisDomain[0], price)
                    }
                }
                var rDomain = d3.extent(data, function (d) {
                    return d[rCat];
                });

                function getAdditionalTickSize(boundary, value) {
                    return Math.abs(boundary - value) / 10;
                }

                function normalizeDomain(config) {
                    var types = {
                        NUMBER: getNumberAxisDomain,
                        DATE: getDateAxisDomain
                    };

                    return types[config.type || 'NUMBER'](config);
                }

                function getNumberAxisDomain(config) {
                    var range = config.range || 0.05;
                    var values = d3.extent(config.data, function (d) {
                        return d[config['field']];
                    });

                    if (values[0] === values[1]) {
                        return [(1 - range) * values[0], (1 + range) * values[1]];
                    }
                    return values;
                }

                function getDateAxisDomain(config) {
                    var values = d3.extent(config.data, function (d) {
                        return d[config['field']];
                    });

                    if (values[0] === values[1]) {
                        return [moment(values[0]).add(-1, 'days').toDate(), moment(values[1]).add(1, 'days').toDate()];
                    }
                    return values;
                }

                var x = d3.scaleTime()
                    .range([0, width])
                    .domain(xAxisDomain);

                var y = d3.scaleLinear()
                    .range([height, 0])
                    .domain(yAxisDomain);

                var r = d3.scaleLinear()
                    .range([5, 10])
                    .domain(rDomain);

                var tip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html(function (d) {
                        return 'CUSIP: ' + d['cusip'] + ' <br> ' +
                            'Maturity Date: ' + $filter('mtvDate')(d[xCat]) + ' <br> ' +
                            'Price: ' + $filter('number')(d['price'], 3) + ' <br> ' +
                            'Yield ' + d['yield'] + ' <br> ' +
                            'Weight ' + d['weight'] + ' <br> ';
                    });

                var priceTip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html("Price: " + price);

                svg.call(tip);
                svg.call(priceTip);

                svg.append('rect')
                    .attr('width', width)
                    .attr('height', height)
                    .attr('fill', 'transparent');

                var xAxis = d3.axisBottom(x)
                    .tickValues(ChartUtil.getTicks(xAxisDomain))
                    .tickSize(-height)
                    .tickFormat(function (d) {
                        return $filter('mtvDate')(d);
                    })
                    .tickPadding(10);

                var yAxis = d3.axisLeft(y)
                    .ticks(6)
                    .tickSize(-width)
                    .tickPadding(10);

                var gX = svg.append('g')
                    .attr('class', 'axis axis--x')
                    .attr('transform', 'translate(0, ' + height + ')')
                    .call(xAxis);

                var gY = svg.append('g')
                    .attr('class', 'axis axis--y')
                    .call(yAxis);

                var objects = svg.append('svg')
                    .classed('objects', true)
                    .attr('width', width)
                    .attr('height', height);

                objects.append('svg:line')
                    .classed('axis-line hAxisLine', true)
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', width)
                    .attr('y2', 0);

                objects.append('svg:line')
                    .classed('axis-line vAxisLine', true)
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', 0)
                    .attr('y2', height);

                if (scope.options.dataField === 'price'  && priceLineShouldBeShown) {
                    objects.append("svg:line")
                        .classed('price-line', true)
                        .attr("x1", 0)
                        .attr("x2", width)
                        .style("stroke", "rgb(125, 92, 92)")
                        .style("stroke-width", "3px")
                        .style("stroke-opacity", "0.7")
                        .on('mouseover', priceTip.show)
                        .on('mouseout', priceTip.hide);
                }

                objects.selectAll('.dot')
                    .data(data)
                    .enter()
                    .append('circle')
                    .classed('dot', true)
                    .classed('active', isActive)
                    .attr('r', radius)
                    .attr('transform', transform)
                    .attr('ng-click', onClick)
                    .style('fill', color)
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide);

                function isActive(d) {
                    return d.active;
                }

                function radius(d) {
                    return r(d[rCat]);
                }

                function onClick(d) {
                    return 'select(' + d.index + ')';
                }

                function color(d) {
                    if (d.active) {
                        return 'blue';
                    }
                    return d.color;
                }

                scope.select = function (index) {
                    if (readOnly) {
                        return;
                    }

                    tip.hide();
                    scope.data.comparables.forEach(function(d) {
                        d.active = false;
                    });

                    scope.data.comparables[index].active = true;
                };

                function transform(d) {
                    return 'translate(' + x(d[xCat]) + ',' + y(d[yCat]) + ')';
                }

                var zoomBeh = d3.zoom()
                    .scaleExtent([0.9, 0.9])
                    .on('zoom', zoom);

                function zoom() {
                    var transform = d3.event.transform;
                    svg.selectAll('.dot')
                        .attr('transform', function (d) {
                            return 'translate(' + transform.applyX(x(d[xCat])) + ', ' + transform.applyY(y(d[yCat])) + ')';
                        });
                    svg.selectAll('.price-line')
                        .attr('transform', function () {
                            return 'translate(0, ' + transform.applyY(y(price)) + ')';
                        });
                    gX.call(xAxis.scale(transform.rescaleX(x)));
                    gY.call(yAxis.scale(transform.rescaleY(y)));
                }

                zoomBeh.scaleTo(svg, 0.9);

                svg.selectAll(".tick text")
                    .call(wrap, x);

                function wrap() {
                    d3.select(this).append("tspan").attr("x", 0).attr("y", y).text(d3.select(this));
                }

                if (!readOnly) {
                    return;
                }

                var colorDomain = d3.scaleOrdinal()
                    .domain(['Last ' + scope.marketType, 'Last Trade'])
                    .range([typeColor[scope.marketType], circleColor.blue]);

                var legend = svg.selectAll('.legend')
                    .data(colorDomain.domain())
                    .enter()
                    .append('g')
                    .classed('legend', true)
                    .attr('transform', function (d, i) {
                        return 'translate(' + i * 120 + ', 0)';
                    });

                svg.append('g')
                    .classed('chart-legend', true)
                    .append('text')
                    .attr('x', 0)
                    .attr('y', -16)
                    .attr('dy', '.45em')
                    .text(capitalizeFirstLetter(dataField) + ' to Maturity Date');

                var legendCircleMargin = width - 195;

                legend.append('circle')
                    .attr('r', 7)
                    .attr('cx', legendCircleMargin)
                    .attr('cy', -15)
                    .attr('fill', colorDomain);

                legend.append('text')
                    .attr('x', legendCircleMargin + 10)
                    .attr('y', -16)
                    .attr('dy', '.45em')
                    .text(function (d) {
                        return d;
                    });
            }

            function capitalizeFirstLetter(input) {
                return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
            }
        }
    }
})();
