(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('ReadOnlyController', ReadOnlyController);

    ReadOnlyController.$inject = [];

    function ReadOnlyController() {
        var vm = this;
    }
})();
