(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('direct-access.trade-mode.search.approve.notes-and-flags', {
            url: '',
            data: {
                authorities: []
            },
            params: {
                approveObj: null,
                viewType: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal',
                function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/standard/trade-mode/search/notes-and-flags/notes-and-flags.html',
                        controller: 'NotesAndFlagsController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        windowClass: 'vertical-modal',
                        resolve: {
                            approveEntity: function () {
                                return $stateParams.approveObj;
                            },
                            viewType: function () {
                                return $stateParams.viewType;
                            }
                        }
                    }).result.then(function (param) {
                        $state.go('standard.trade-mode.search.approved.report', {
                            approvedHash: param.hash,
                            cusip: $stateParams.cusip,
                            price: $stateParams.price,
                            size: $stateParams.size,
                            tradeSide: $stateParams.tradeSide,
                            tradeId: $stateParams.tradeId,
                            key: $stateParams.key
                        }, null, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
        });
    }
})();
