(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('equals', equals);

    function equals() {
        return {
            require: 'ngModel',
            scope: {
                otherModelValue: '=equals'
            },
            link: link
        };

        function link(scope, element, attributes, ngModel) {
            ngModel.$validators.equals = function (modelValue) {
                if (!(modelValue || scope.otherModelValue)) {
                    return true;
                }
                return modelValue === scope.otherModelValue;
            };

            scope.$watch('otherModelValue', function () {
                ngModel.$validate();
            });
        }
    }
})();
