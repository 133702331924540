(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('mtvDate', mtvDate)
        .filter('mtvDateTime', mtvDateTime);

    function mtvDate() {
        return function (value, pattern) {
            return formatInstant(value, pattern || 'MM/DD/YYYY');
        }
    }

    function mtvDateTime() {
        return function (value, pattern) {
            return formatInstant(value, pattern || 'MM/DD/YYYY hh:mm:ss A');
        }
    }

    function formatInstant(value, pattern) {
        if (!value) {
            return null;
        }
        return moment(value).parseZone()
            .format(pattern);
    }
})();
