(function () {
    'use strict';

    var tradePanel = {
        templateUrl: 'app/components/trade-panel/trade-panel.template.html',
        controller: TradePanelController,
        bindings: {
            dpSettings: '<',
            securityDetails: '<',
            marketData: '<',
            filteringMarketType: '<',
            bwIncluded: '<',
            rtrsData: '<',
            finraData: '<',
            comparableData: '<',
            bestExecutionStatistics: '<',
            closestPeer: '<',
            toleranceChecks: '<',
            userInput: '<',
            isDataReady: '=',
            approvedHash: '=',
            searchHash: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('tradePanel', tradePanel);

    TradePanelController.$inject = ['$scope', '$state', 'Cusip'];

    function TradePanelController($scope, $state, Cusip) {
        var vm = this;

        vm.approve = approve;
        vm.comparablesShouldBeShown = comparablesShouldBeShown;
        vm.isApproveDisabled = isApproveDisabled;
        vm.selectedComparables = 0;

        $scope.$watch('$ctrl.comparableData.comparables', countSelected, true);

        function countSelected(comparables) {
            if (!comparables) {
                vm.selectedComparables = 0;
                return;
            }

            vm.selectedComparables = comparables.filter(function (item) {
                return item.selected;
            }).length;
        }

        function processComparables(data) {
            if(data === undefined || data === null) {
                return null;
            }
            return data
                .filter(function (el) {
                    return el.selected;
                }).map(function (comparable) {
                    return comparable.cusip;
                });
        }
        function getCorporateApproveObject() {
            vm.finraData = vm.finraData || {};

            var corporate = {};
            if (Cusip.getDataPermission.marketData(vm.dpSettings).value) {
                corporate['marketType'] = vm.filteringMarketType;
                corporate['bwIncluded'] = vm.bwIncluded;
            }
            if (Cusip.getDataPermission.tradeHistory(vm.dpSettings).value) {
                corporate['finraChartOptions'] = vm.finraData.chartOptions;
                corporate['reportingPartyType'] = vm.finraData.reportingPartyType;
                corporate['contraPartyType'] = vm.finraData.contraPartyType;
            }

            return angular.extend({searchHash: vm.searchHash}, corporate);
        }

        function getApproveObject() {
            var municipal = {};
            if (Cusip.getDataPermission.marketData(vm.dpSettings).value) {
                municipal['marketType'] = vm.filteringMarketType;
                municipal['bwIncluded'] = vm.bwIncluded;
            }
            if (Cusip.getDataPermission.tradeHistory(vm.dpSettings).value) {
                municipal['rtrsDataType'] =  vm.rtrsData.rtrsDataType;
                municipal['rtrsPartyType'] = vm.rtrsData.rtrsPartyType;
            }
            if (Cusip.getDataPermission.comparables(vm.dpSettings).value) {
                municipal['selectedComparables'] = processComparables(vm.comparableData.comparables);
                municipal['comparablesType'] =  vm.comparableData.type;
            }

            return angular.extend({searchHash: vm.searchHash}, municipal);
        }

        function approve() {
            var approveObj = Cusip.isView.corporate(vm.dpSettings.uiView)
                ? getCorporateApproveObject()
                : getApproveObject();
            $state.go($state.current.name + '.notes-and-flags', {approveObj: approveObj, viewType: vm.dpSettings.uiView});
        }

        function isApproveMode() {
            return $state.current.name === 'standard.trade-mode.search.approve'
                || $state.current.name === 'direct-access.trade-mode.search.approve';
        }

        function comparablesShouldBeShown() {
            if (!vm.comparableData || vm.comparableData.comparables.length === 0) {
                return false;
            }
            return !Cusip.isView.corporate(vm.dpSettings.uiView) && isApproveMode();
        }

        function isApproveDisabled() {
            return vm.dpSettings.message;
        }
    }
})();
