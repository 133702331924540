(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('Group', Group);

    Group.$inject = ['$resource'];

    function Group($resource) {
        var service = $resource('api/authorities', {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });

        return service;
    }
})();
