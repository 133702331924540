(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('parseFloat', parseFloat);

    parseFloat.$inject = ['$filter'];

    function parseFloat($filter) {
        return function (item) {
            if(item % 1 !== 0){
                return item;
            }
            return $filter('number')(item, 1);
        }
    }
})();
