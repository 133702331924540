(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('UserManagementDirectAccessController', UserManagementDirectAccessController);

    UserManagementDirectAccessController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DirectAccess'];

    function UserManagementDirectAccessController($scope, $stateParams, $uibModalInstance, DirectAccess) {
        var vm = this;
        vm.close = close;
        vm.reset = reset;
        vm.submit = submit;
        vm.minDaysToLive = 1;
        vm.maxDaysToLive = 2000;

        function close() {
            $uibModalInstance.close();
        }

        function reset() {
            vm.daysToLive = '';
            vm.url = '';
            $scope.directAccessForm.$setPristine();
        }

        function submit() {
            $scope.directAccessForm.daysToLive.$dirty = true;

            if (!$scope.directAccessForm.$valid) {
                return;
            }

            DirectAccess.get({
                'daysToLive': vm.daysToLive,
                'userId': $stateParams.userId
            }, function (result) {
                vm.url = result.url;
            });
        }
    }
})();
