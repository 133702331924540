(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('tradeSideFilter', tradeSideFilter);

    function tradeSideFilter() {
        return function (tradeSide) {
            if(tradeSide.toLowerCase() === 'sell'){
                return 'sell-side'
            }

            return 'buy-side';
        }
    }
})();
