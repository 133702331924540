(function () {
    'use strict';

    var controlForm = {
        templateUrl: 'app/standard/review-mode/components/control-form/control-form.template.html',
        controller: ControlFormController,
        bindings: {}
    };

    angular
        .module('mtvApp')
        .component('controlForm', controlForm);

    ControlFormController.$inject = ['$state', '$filter', 'Company', 'Account', 'Principal', 'CounterParty', 'ViewType', 'FinraFeedType', 'TradeSide', '$scope'];

    function ControlFormController($state, $filter, Company, Account, Principal, CounterParty, ViewType, FinraFeedType, TradeSide, $scope) {
        var vm = this;

        vm.togglePickerFrom = togglePickerFrom;
        vm.togglePickerTo = togglePickerTo;
        vm.updateMaxDate = updateMaxDate;
        vm.updateMinDate = updateMinDate;
        vm.hasPermission = hasPermission;
        vm.search = search;
        vm.reviewType = $state.current.name.split('.')[1];
        vm.isReviewMode = vm.reviewType === 'review-mode';
        vm.cusip = $state.params.cusip;
        vm.trader = $state.params.trader;
        vm.unreadOnlyFlag = $state.params.unreadOnlyFlag === 'true';
        vm.outOfToleranceCheckFlag = $state.params.outOfToleranceCheckFlag === 'true';
        vm.insufficientDataFlag = $state.params.insufficientDataFlag === 'true';
        vm.bulkUploadFlag = $state.params.bulkUploadFlag === 'true';
        vm.commentsFlag = $state.params.commentsFlag === 'true';
        vm.userCommentsFlag = $state.params.userCommentsFlag === 'true';
        vm.correctionFlag = $state.params.correctionFlag === 'true';
        vm.counterPartyFlags = [];
        vm.finraFeedFlags = [];
        vm.tradeSideFlags = [];
        vm.dropDownSettings = {smartButtonMaxItems: 3};
        vm.from = $state.params.from;
        vm.to = $state.params.to;

        setPermissions();
        getCounterPartyFlags();
        getFinraFeedFlags();
        getTradeSideFlags();

        Company.getAllUsers().query(function (result) {
            vm.users = result;
        });
        vm.user = Account.get();

        vm.pickerFrom = {
            date: null,
            isOpen: false
        };

        vm.pickerTo = {
            date: null,
            isOpen: false
        };

        if ($state.params.from) {
            vm.pickerFrom.date = new Date($state.params.from)
        }
        if ($state.params.to) {
            vm.pickerTo.date = new Date($state.params.to)
        }

        vm.dateFromOptions = {
            maxDate: vm.pickerTo.date,
            showWeeks: false
        };

        vm.dateToOptions = {
            minDate: vm.pickerFrom.date,
            showWeeks: false
        };

        function togglePickerFrom() {
            vm.pickerFrom.isOpen = !vm.pickerFrom.isOpen;
        }

        function togglePickerTo() {
            vm.pickerTo.isOpen = !vm.pickerTo.isOpen;
        }

        function updateMaxDate() {
            vm.dateFromOptions.maxDate = vm.pickerTo.date;
        }

        function updateMinDate() {
            vm.dateToOptions.minDate = vm.pickerFrom.date;
        }

        function getSelectedFlags(flags) {
            return flags.filter(function (flag) {
                return flag.checked === true;
            })
            .map(function (flag) {
                return flag.value;
            });
        }

        function getCounterPartyFlags() {
            CounterParty.getValues[vm.reviewType].query({}, function (data) {
                vm.counterPartyFlags = data.map(function (item) {
                    return {
                        id: 'cpid_' + data.indexOf(item),
                        label: item,
                        value: item,
                        checked: $state.params.counterPartyFlags
                        && $state.params.counterPartyFlags.indexOf(item) !== -1
                    }
                });
            });
        }

        function getFinraFeedFlags() {
            var stateFlags = [];
            if ($state.params.finraFeedFlags) {
                stateFlags = !Array.isArray($state.params.finraFeedFlags)
                    ? [$state.params.finraFeedFlags]
                    : $state.params.finraFeedFlags;
            }
            FinraFeedType.getValues[vm.reviewType].query({}, function (data) {
                vm.finraFeedFlags = data.map(function (item) {
                    var filteredValue = $filter('finraFeedTypeFilter')(item);
                    return {
                        id: 'vtid_' + data.indexOf(item),
                        label: filteredValue,
                        value: item,
                        checked: stateFlags.indexOf(item) !== -1
                    }
                });
            });
        }

        function getTradeSideFlags() {
            vm.tradeSideFlags = TradeSide.getValues().map(function (item, idx) {
                return {
                    id: 'tsid_' + idx,
                    label: item.toUpperCase(),
                    value: item,
                    checked: $state.params.tradeSideFlags
                        && $state.params.tradeSideFlags.indexOf(item) !== -1
                }
            });
        }

        function setPermissions() {
            Principal.getCompanyPermissions().then(function (result) {
                vm.permissions = result;
            });
        }

        function hasPermission(permission) {
            return vm.permissions
                ? vm.permissions[permission]
                : false;
        }

        function search() {
            $state.go($state.current.name, {
                'trader': vm.trader,
                'cusip': vm.cusip,
                'unreadOnlyFlag': vm.unreadOnlyFlag,
                'outOfToleranceCheckFlag': vm.outOfToleranceCheckFlag,
                'insufficientDataFlag': vm.insufficientDataFlag,
                'bulkUploadFlag': vm.bulkUploadFlag,
                'commentsFlag': vm.commentsFlag,
                'userCommentsFlag': vm.userCommentsFlag,
                'correctionFlag': vm.correctionFlag,
                'counterPartyFlags': getSelectedFlags(vm.counterPartyFlags),
                'finraFeedFlags': getSelectedFlags(vm.finraFeedFlags),
                'tradeSideFlags': getSelectedFlags(vm.tradeSideFlags),
                'from': $filter('mtvDate')(vm.pickerFrom.date, 'YYYY-MM-DD'),
                'to': $filter('mtvDate')(vm.pickerTo.date, 'YYYY-MM-DD'),
                'activePosition': undefined,
                'outerPage': undefined,
                'innerPage': undefined
            });
        }

        $scope.$on('$stateChangeStart', function () {
            vm.pickerFrom.isOpen = false;
            vm.pickerTo.isOpen = false;
        });
    }
})();
