(function () {
    'use strict';

    var corporateDataReport = {
        templateUrl: 'app/components/corporate-data/corporate-data-report.template.html',
        bindings: {
            report: '<',
            bestExecutionReview: '<',
            approvedHash: '<',
            marketType: '<',
            isReviewMode: '<',
            viewType: '<',
            reportingPartyType: '<',
            contraPartyType: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('corporateDataReport', corporateDataReport);
})();
