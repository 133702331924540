(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.trade-mode.search.approve', {
            //TODO: should be changed to particular search
            url: '',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'header-container': {
                    template: '<header state="search" dp-settings="vm.dpSettings"></header>'
                },
                'search-container': {
                    templateUrl: 'app/standard/trade-mode/search/approve/approve.html'
                }
            },
            onEnter: ['$rootScope',
                function ($rootScope) {
                    $rootScope.searchDisabled = true;
                }]
        });
    }
})();
