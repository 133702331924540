(function () {
    'use strict';

    var mtvWatermark = {
        templateUrl: 'app/components/watermark/watermark.template.html',
        controller: MtvWatermarkController,
    };

    angular
        .module('mtvApp')
        .component('mtvWatermark', mtvWatermark);

    mtvWatermark.$inject = ['$state', '$window', '$scope'];

    function MtvWatermarkController($state, $window, $scope) {
        var vm = this;

        function init() {
            vm.watermarks = [];

            var totalPageWidth = 450;
            angular.forEach(angular.element('section'), function (item) {
                totalPageWidth += item.clientHeight;
            });

            var viewPortHeight = $window.innerHeight;
            var watermarksOnPage = Math.floor(totalPageWidth / viewPortHeight);

            var fontSize = viewPortHeight / 5;
            var left = $state.current.name === 'read-only.report' ? 25 : 17;
            var top = viewPortHeight / 4;
            var width = 700;

            for (var i = 0; i < watermarksOnPage; i++) {
                vm.watermarks.push({
                    fontSize: fontSize,
                    left: left,
                    top: top,
                    width: width
                });
                top += viewPortHeight;
            }
        }

        $scope.$watch(function () {
            return angular.element('section').outerHeight();
        }, init);

        angular.element($window).bind('resize', function () {
            $scope.$apply(init);
        });
    }
})();

