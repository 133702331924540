(function () {
    'use strict';

    var notCorporateData = {
        templateUrl: 'app/components/not-corporate-data/not-corporate-data.template.html',
        controller: NonCorporateDataController,
        bindings: {
            userInput: '<',
            dpSettings: '<',
            bestExecutionStatistics: '<',
            closestPeer: '<',
            toleranceChecks: '<',
            marketData: '<',
            marketType: '<',
            filteringMarketType: '=',
            bwIncluded: '=',
            rtrsData: '<',
            filteredRtrsData: '<',
            comparableData: '<',
            isApproved: '<',
            onFilterValueChange: '&'
        }
    };

    angular
        .module('mtvApp')
        .component('notCorporateData', notCorporateData);

    NonCorporateDataController.$inject = ['Cusip'];

    function NonCorporateDataController(Cusip) {
        var vm = this;

        vm.partyTypeItems = [
            {value: 'All', filterName: 'rtrsPartyType'},
            {value: 'Broker/Dealer', filterName: 'rtrsPartyType'},
            {value: 'Alternative Trading System', filterName: 'rtrsPartyType'}
        ];

        vm.tradeTypeItems = [
            {value: 'All', displayName: 'All', filterName: 'rtrsDataType'},
            {value: 'SALE', displayName: 'Customer Bought', filterName: 'rtrsDataType'},
            {value: 'BUY', displayName: 'Customer Sold', filterName: 'rtrsDataType'},
            {value: 'INT_DEALER', displayName: 'Inter-dealer', filterName: 'rtrsDataType'}
        ];

        vm.bestExecutionPermission = Cusip.getDataPermission.bestExecution(vm.dpSettings);

        vm.setFilterValue = setFilterValue;
        vm.select = select;

        function setFilterValue(item) {
            vm.onFilterValueChange({item: item});
        }

        function select(item) {
            vm.filteredRtrsData.forEach(function (d) {
                d.active = false;
            });
            item.active = true;
        }
    }
})();
