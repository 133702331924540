(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('BestExecutionReview', BestExecutionReview);

    BestExecutionReview.$inject = ['$resource'];

    function BestExecutionReview($resource) {

        function getToleranceChecks() {
            return $resource('api/best-execution-review/checks/:searchHash', {}, {
                'query': {
                    method: 'GET',
                    isArray: true
                }
            })
        }

        function getBestExecutionStatistics() {
            return $resource('api/best-execution-review/:searchHash', {}, {
                'query': {
                    method: 'GET'
                }
            })
        }

        function getClosestPeer() {
            return $resource('api/best-execution-review/closest-peers/:searchHash', {}, {
                'query': {
                    method: 'GET'
                }
            })
        }

        return {
            getToleranceChecks: getToleranceChecks,
            getBestExecutionStatistics: getBestExecutionStatistics,
            getClosestPeer: getClosestPeer
        }
    }
})();
