(function () {
    'use strict';

    angular
        .module('mtvApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.router',
            'infinite-scroll',
            // mtv-needle-angularjs-add-module MuniTRADE View will add new module here
            'angular-loading-bar',
            'rzModule',
            'duScroll',
            'ngAnimate'
        ])
        .run(run);

    run.$inject = ['stateHandler'];

    function run(stateHandler) {
        stateHandler.initialize();
    }
})();
