(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('SearchController', SearchController);

    SearchController.$inject = ['$scope', '$rootScope', '$http', '$q', '$stateParams', '$state',
        'BestExecutionReview', 'Snapshot', 'Cusip', '$uibModal', '$filter'];

    function SearchController($scope, $rootScope, $http, $q, $stateParams, $state,
                              BestExecutionReview, Snapshot, Cusip, $uibModal, $filter) {
        var vm = this;
        vm.isReady = isReady;
        vm.isApproved = isApproved;
        vm.setFilterRtrsValue = setFilterRtrsValue;
        vm.setDataFinraField = setDataFinraField;
        vm.setFilterFinraValue = setFilterFinraValue;
        vm.isView = Cusip.isView;
        vm.getDataPermission = Cusip.getDataPermission;

        vm.approvedHash = '';

        init();

        // TODO: Dirty hack here. Refactor to separate view.
        $scope.$on('mtvApp:approve', function (event, args) {
            vm.approvedHash = args.approvedHash;
        });

        function isReady() {
            return isReadyCorporate() || isReadyNotCorporate();
        }

        function isReadyCorporate() {
            //TODO: add logic depends on viewType
            return !angular.isUndefined(vm.securityDetails)
                && (!angular.isUndefined(vm.finraData) || !angular.isUndefined(vm.marketData));
        }

        function isReadyNotCorporate() {
            return !angular.isUndefined(vm.securityDetails) &&
                !angular.isUndefined(vm.marketData) &&
                !angular.isUndefined(vm.rtrsData) &&
                !angular.isUndefined(vm.comparableData) &&
                !angular.isUndefined(vm.toleranceChecks) &&
                !angular.isUndefined(vm.bestExecutionStatistics) &&
                !angular.isUndefined(vm.closestPeer);
        }

        function isApproved() {
            return vm.approvedHash.length > 0 || $state.params.approvedHash;
        }

        function initParams() {
            $stateParams.tradeSide = ($stateParams.tradeSide || '').toLowerCase();

            var userInput = {};
            userInput.tradeSide = $stateParams.tradeSide.toUpperCase();
            userInput.price = {amount: parseInput($stateParams.price, 'price')};
            userInput.size = parseInput($stateParams.size, 'size');
            userInput.cusip = $filter('limitTo')($stateParams.cusip, 9);
            userInput.tradeId = $filter("limitTo")($stateParams.tradeId, 20);
            vm.userInput = userInput;

            vm.marketType = vm.filteringMarketType = marketTypeBySide(userInput.tradeSide);
            vm.bwIncluded = true;
        }

        function parseInput(value, type) {
            if (value === null || value === undefined || isNaN(value)) {
                return null;
            }
            if (type === 'price') {
                return parseFloat(value) || null;
            }
            if (type === 'size') {
                return parseInt(value) || null;
            }
            return null;
        }

        function init() {
            var securityPromise;
            var marketDataPromise;
            var tradeHistoryPromise;
            var comparablesPromise;
            if (isApproved()) {
                return;
            }

            initParams();
            vm.filteredFinraData = [];
            vm.filteredRtrsData = [];

            Cusip.fetchDataPermissionSettings(vm.userInput, vm.searchHash, isDirectAccessMode()).then(
                function (result) {
                    vm.dpSettings = result.data.settings;
                    vm.searchHash = result.data.searchHash;
                    fetchData();
                }, function () {
                    vm.dpSettings = {message: 'Application has encountered an error during processing CUSIP input. Please contact your Systems Administrator if you have any questions.'};
                    fetchData();
                });

            function fetchData() {
                if (!!vm.dpSettings.message) {
                    $rootScope.searchDisabled = false;
                    return;
                }
                securityPromise = fetchSecurityDetails();
                if (!vm.getDataPermission.bestExecution(vm.dpSettings).value) {
                    $rootScope.searchDisabled = false;
                    return;
                }
                if (vm.isView.corporate(vm.dpSettings.uiView)) {
                    fetchCorporateData();
                } else if (vm.isView.municipal(vm.dpSettings.uiView)) {
                    fetchMunicipalData();
                } else {
                    $rootScope.searchDisabled = false;
                }
            }

            // TODO Merge two methods below
            function fetchCorporateData() {
                BestExecutionReview.getToleranceChecks().query({
                    searchHash: vm.searchHash
                }).$promise.then(fetchCorporateTradeData);
            }

            function fetchMunicipalData() {
                BestExecutionReview.getToleranceChecks().query({
                    searchHash: vm.searchHash
                }).$promise.then(fetchMunicipalTradeData);
            }

            function fetchCorporateTradeData(toleranceChecks) {
                vm.toleranceChecks = toleranceChecks;
                var bestExecutionPromise = fetchBestExecutionStatistics();

                marketDataPromise = fetchMarketData();
                tradeHistoryPromise = fetchFinraTradeData();

                bestExecutionPromise.then(function () {
                    if (isDirectAccessMode()) {
                        vm.daKey = $stateParams.key;
                        openCorporateModal();
                    } else {
                        $q.all([
                            securityPromise,
                            marketDataPromise,
                            tradeHistoryPromise
                        ]).then(function () {
                            $rootScope.searchDisabled = false;
                        });
                    }
                });
            }

            function fetchMunicipalTradeData(toleranceChecks) {
                vm.toleranceChecks = toleranceChecks;
                var bestExecutionPromise = fetchBestExecutionStatistics();
                var closestPeerPromise = fetchClosestPeer();

                marketDataPromise = fetchMarketData();
                tradeHistoryPromise = fetchTradeHistoryData();
                comparablesPromise = fetchComparables();

                $q.all([
                    bestExecutionPromise,
                    closestPeerPromise
                ]).then(function () {
                    if (isDirectAccessMode()) {
                        vm.daKey = $stateParams.key;
                        openModal();
                    } else {
                        $q.all([
                            securityPromise,
                            marketDataPromise,
                            tradeHistoryPromise,
                            comparablesPromise
                        ]).then(function () {
                            $rootScope.searchDisabled = false;
                        });
                    }
                });
            }

            function openModal() {
                $uibModal.open({
                    templateUrl: 'app/direct-access/trade-mode/search/best-execution-review-modal/best-execution-review-modal.template.html',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'da-modal',
                    controller: ['$uibModalInstance', '$uibModalStack', '$scope',
                        function ($uibModalInstance, $uibModalStack, $scope) {
                            $scope.$on('$locationChangeStart', function (event, next, current) {
                                if ($uibModalInstance) {
                                    $uibModalInstance.dismiss('cancel');
                                }
                            });
                            $uibModalStack.dismissAll();
                            this.uiView = vm.dpSettings.uiView;
                            this.isView = vm.isView;
                            this.bestExecutionStatistics = vm.bestExecutionStatistics;
                            this.closestPeer = vm.closestPeer;
                            this.toleranceChecks = vm.toleranceChecks;
                            this.review = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                            this.approve = function () {
                                $q.all(securityPromise,
                                    marketDataPromise,
                                    tradeHistoryPromise,
                                    comparablesPromise)
                                    .then(approve);
                                $uibModalInstance.close();
                            };
                        }],
                    controllerAs: 'vm'
                }).closed.then(function () {
                    fetchComparables();
                });
            }

            function openCorporateModal() {
                $uibModal.open({
                    templateUrl: 'app/direct-access/trade-mode/search/best-execution-review-modal/best-execution-review-modal.template.html',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'da-modal',
                    controller: ['$uibModalInstance', '$uibModalStack', '$scope',
                        function ($uibModalInstance, $uibModalStack, $scope) {
                            $scope.$on('$locationChangeStart', function (event, next, current) {
                                if ($uibModalInstance) {
                                    $uibModalInstance.dismiss('cancel');
                                }
                            });
                            $uibModalStack.dismissAll();
                            this.uiView = vm.dpSettings.uiView;
                            this.isView = vm.isView;
                            this.bestExecutionStatistics = vm.bestExecutionStatistics;
                            this.toleranceChecks = vm.toleranceChecks;
                            this.review = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                            this.approve = function () {
                                $q.all(securityPromise,
                                    tradeHistoryPromise)
                                    .then(approve);
                                $uibModalInstance.close();
                            };
                        }],
                    controllerAs: 'vm'
                })
            }
        }

        function approve() {
            var obj = {
                comments: "",
                flags: [],
                searchHash: vm.searchHash,
                marketType: vm.marketType,
                tradeId: vm.userInput.tradeId
            };

            Snapshot.save(obj, onAddSnapshotSuccess);

            function onAddSnapshotSuccess(res) {
                $state.go('standard.trade-mode.search.approved.report', {
                    cusip: vm.userInput.cusip,
                    approvedHash: res.hash,
                    size: vm.userInput.size,
                    price: vm.userInput.price.amount,
                    tradeSide: vm.userInput.tradeSide,
                    key: vm.daKey,
                    tradeId: vm.userInput.tradeId
                }, null, {reload: false});
            }
        }

        function fetchSecurityDetails() {
            var deferred = $q.defer();
            $http.get('api/trade-mode/security-details/' + vm.searchHash).then(function (result) {
                vm.securityDetails = result.data;
                deferred.resolve();
            }, function () {
                vm.securityDetails = {cusip: $stateParams.cusip};
                deferred.resolve();
            });
            return deferred.promise;
        }

        function fetchMarketData() {
            var deferred = $q.defer();
            if (!vm.getDataPermission.marketData(vm.dpSettings).value) {
                deferred.resolve();
                return deferred.promise;
            }
            $http.get('api/trade-mode/market-data/' + vm.searchHash, {}).then(function (result) {
                vm.marketData = result.data;
                deferred.resolve();
            });
            return deferred.promise;
        }

        function fetchTradeHistoryData() {
            var deferred = $q.defer();
            $http.get('api/trade-mode/history-data/' + vm.searchHash, {}).then(function (result) {
                vm.originRtrsData = result.data;
                vm.rtrsData = {
                    data: result.data,
                    dropdownValue: 'All',
                    rtrsDataType: "All",
                    rtrsPartyType: "All"
                };
                filterRtrsData();
                deferred.resolve();
            });
            return deferred.promise;
        }

        function fetchFinraTradeData() {
            var deferred = $q.defer();
            if (!vm.getDataPermission.tradeHistory(vm.dpSettings).value) {
                deferred.resolve();
                return deferred.promise;
            }

            $http.get('api/trade-mode/finra-history-data/' + vm.searchHash, {}).then(function (result) {
                vm.originFinraData = result.data.tradeHistory;
                vm.finraData = {
                    data: result.data.tradeHistory,
                    showRecentTrades: result.data.showRecentTrades,
                    chartOptions: {
                        dataField: 'price',
                        dropdownLabel: 'Price to Date'
                    },
                    reportingPartyType: 'All',
                    contraPartyType: 'All'
                };
                filterFinraData();
                deferred.resolve();
            });

            return deferred.promise;
        }

        function fetchComparables() {
            var deferred = $q.defer();
            $http.get('api/trade-mode/comparables/' + vm.searchHash, {}).then(function (result) {
                vm.comparableData = {
                    comparables: result.data,
                    type: 'yield'
                };
                vm.comparableData.comparables.forEach(function (item) {
                    angular.extend(item, {filtered: true, selected: false});
                });
                deferred.resolve();
            });
            return deferred.promise;
        }

        function fetchBestExecutionStatistics() {
            var deferred = $q.defer();
            BestExecutionReview.getBestExecutionStatistics().query({
                    searchHash: vm.searchHash
                }, function (result) {
                    vm.bestExecutionStatistics = result;
                    deferred.resolve();
                }
            );
            return deferred.promise;
        }

        function fetchClosestPeer() {
            var deferred = $q.defer();
            BestExecutionReview.getClosestPeer().query({
                    searchHash: vm.searchHash
                }, function (result) {
                    vm.closestPeer = result;
                    deferred.resolve();
                }
            );
            return deferred.promise;
        }

        function marketTypeBySide(tradeSide) {
            var side = {
                buy: 'Offering',
                sell: 'Bid'
            };

            var rawSide = tradeSide.toLowerCase();
            return side[rawSide];
        }

        function setFilterRtrsValue(item) {
            vm.rtrsData[item.filterName] = item.value;
            if (item.displayName) {
                vm.rtrsData.dropdownValue = item.displayName;
            }
            filterRtrsData();
        }

        function setDataFinraField(item) {
            vm.finraData.chartOptions.dataField = item.dataField;
            vm.finraData.chartOptions.dropdownLabel = item.value;
        }

        function setFilterFinraValue(item) {
            vm.finraData[item.filterName] = item.value;
            filterFinraData();
        }

        function filterFinraData() {
            vm.finraData.data = vm.originFinraData.filter(function (item) {
                return matchesFilter(vm.finraData, item, 'reportingPartyType')
                    && matchesFilter(vm.finraData, item, 'contraPartyType');
            });
        }

        function filterRtrsData() {
            vm.rtrsData.data = vm.originRtrsData.filter(function (item) {
                return matchesFilter(vm.rtrsData, item, 'rtrsPartyType')
                    && matchesFilter(vm.rtrsData, item, 'rtrsDataType');
            });
        }

        function matchesFilter(data, item, filterName) {
            return data[filterName] === 'All' || item[filterName] === data[filterName];
        }

        function isDirectAccessMode() {
            return $state.current.name.indexOf('direct-access') !== -1;
        }
    }
})();
