(function () {
    'use strict';

    var securityDetails = {
        templateUrl: ['$element', '$attrs', function (element, attrs) {
            if (attrs.type) {
                return 'app/components/security-details/security-details-' + attrs.type + '.template.html';
            }
        }],
        controller: SecurityDetailsController,
        bindings: {
            data: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('securityDetails', securityDetails);

    SecurityDetailsController.$inject = ['$filter'];

    function SecurityDetailsController($filter) {
        var vm = this;

        vm.obligors = obligors;
        vm.showObligors = showObligors;
        vm.shouldShowDots = shouldShowDots;

        function obligors(values, startingIndex) {
            if (!values) {
                return '';
            }

            return values.slice(startingIndex)
                .map(function (value) {
                    return $filter('obligor')(value);
                }).join('\r\n');
        }

        function showObligors(obligorData) {
            if(!obligorData) {
                return;
            }

            if(shouldShowDots(obligorData)) {
                return obligorData.slice(0, 4);
            }

            return obligorData;
        }

        function shouldShowDots(obligorData) {
            if(!obligorData) {
                return false;
            }

            return obligorData.length > 5;
        }
    }
})();
