(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('finraTradeHistoryChart', finraTradeHistoryChart);

    finraTradeHistoryChart.$inject = ['$filter', '$compile', 'ChartUtil'];

    function finraTradeHistoryChart($filter, $compile, ChartUtil) {
        return {
            replace: true,
            restrict: 'E',
            template: '<div class="chart finra-chart">' +
            '<div id="history-scatter"></div>' +
            '</div>',
            scope: {
                data: '=',
                options: '<',
                price: '<'
            },
            link: linkFunc
        };

        function linkFunc(scope, element, attrs) {
            var readOnly = attrs.readOnly !== undefined;
            var priceLineShouldBeShown, price;

            var margin = {top: 30, right: 30, bottom: 50, left: 50},
                outerWidth = 570,
                outerHeight = 380,
                width = outerWidth - margin.left - margin.right,
                height = outerHeight - margin.top - margin.bottom;

            var svg = d3.select('#history-scatter')
                .append('svg')
                .attr('width', outerWidth)
                .attr('height', outerHeight)
                .append('g')
                .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

            scope.$watch('data', onDataChange, true);
            scope.$watch('options', onOptionsChange, true);

            function onDataChange(newVal) {
                if (!newVal || !scope.options) {
                    return;
                }
                svg.selectAll('*').remove();
                var data = newVal.data;
                var field = scope.options.dataField.toLowerCase();
                var graphData = prepareData(data, field);
                var parsedPrice = parsePrice(scope.price);
                priceLineShouldBeShown = priceIsNumber(parsedPrice)
                    && data.length > 0
                    && scope.options.dataField === 'price';
                price = priceLineShouldBeShown
                    ? parsedPrice
                    : null;
                render(graphData, field);
                $compile(element)(scope);
            }

            function onOptionsChange(newVal) {
                if (!newVal || !newVal.data || !newVal.data.dataField) {
                    return;
                }
                var data = newVal.data;
                var field = newVal.dataField.toLowerCase();
                svg.selectAll('*').remove();
                var graphData = prepareData(data, field);
                var parsedPrice = parsePrice(scope.price);
                priceLineShouldBeShown = priceIsNumber(parsedPrice)
                    && scope.data.length > 0
                    && data.dataField === 'price';
                price = priceLineShouldBeShown
                    ? parsedPrice
                    : null;
                render(graphData, field);
                $compile(element)(scope);
            }

            function prepareData(data, field) {
                var chartData = angular.copy(data);

                chartData.forEach(function (d, index) {
                    d.index = index;
                });

                chartData = chartData.filter(function (el) {
                    return field !== 'price' && el[field] !== null || el[field] > 0;
                });

                chartData.forEach(function (d) {
                    d.executionDateTime = moment($filter('mtvDateTime')(d.executionDateTime, 'YYYY-MM-DD HH:mm:ss')).toDate();
                    d.quantity = $filter('quantityChartValueFilter')(d.quantity);
                });

                return $filter('orderBy')(chartData, function (d) {
                    return d.active;
                });
            }

            function priceIsNumber(price) {
                return price !== null
                    && !isNaN(price)
                    && angular.isNumber(price);
            }

            function parsePrice(value) {
                return value !== null
                    ? parseFloat(value.amount)
                    : null;
            }

            function render(data, field) {
                var xCat = 'executionDateTime',
                    yCat = field;

                var xAxisDomain = normalizeDomain({type: 'DATE', data: data, field: xCat});
                var yAxisDomain = normalizeDomain({data: data, field: yCat});

                function yAxisContainsValues() {
                    return !isNaN(yAxisDomain[0])
                        && !isNaN(yAxisDomain[1]);
                }

                priceLineShouldBeShown = priceLineShouldBeShown && yAxisContainsValues();

                if (priceLineShouldBeShown) {
                    if (yAxisDomain[0] > price) {
                        yAxisDomain[0] = price - getAdditionalTickSize(yAxisDomain[1], price)
                    }
                    if (yAxisDomain[1] < price) {
                        yAxisDomain[1] = price + getAdditionalTickSize(yAxisDomain[0], price)
                    }
                }

                function getAdditionalTickSize(boundary, value) {
                    return Math.abs(boundary - value) / 10;
                }

                var rDomain = d3.extent(data, function (d) {
                    return parseFloat(d.quantity.price);
                });

                function normalizeDomain(config) {
                    var types = {
                        NUMBER: getNumberAxisDomain,
                        DATE: getDateAxisDomain
                    };

                    return types[config.type || 'NUMBER'](config);
                }

                function getNumberAxisDomain(config) {
                    var range = config.range || 0.05;
                    var values = d3.extent(config.data, function (d) {
                        return d[config['field']];
                    });

                    if (values[0] === values[1]) {
                        return [(1 - range) * values[0], (1 + range) * values[1]];
                    }
                    return values;
                }

                function getDateAxisDomain(config) {
                    var values = d3.extent(config.data, function (d) {
                        return d[config['field']];
                    });

                    if (values[0] === values[1]) {
                        return [moment(values[0]).add(-1, 'days').toDate(), moment(values[1]).add(1, 'days').toDate()];
                    }
                    return values;
                }

                var hasUnknownSidePoints = data.filter(function (item) {
                    return item.side === 'Unknown'
                }).length > 0;

                var r = d3.scaleLinear()
                    .range([5, 10])
                    .domain(rDomain);

                var x = d3.scaleTime()
                    .range([0, width])
                    .domain(xAxisDomain);

                var y = d3.scaleLinear()
                    .range([height, 0])
                    .domain(yAxisDomain);

                var colorDomain = hasUnknownSidePoints
                    ? d3.scaleOrdinal()
                        .domain(['Buy', 'Sale', 'Unknown'])
                        .range(['#ff8227', '#2bbd2b', '#8f8b8b'])
                    : d3.scaleOrdinal()
                        .domain(['Buy', 'Sale'])
                        .range(['#ff8227', '#2bbd2b']);

                var tip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html(function (d) {
                        return 'Date/Time: ' + $filter('mtvDateTime')(d[xCat]) + ' <br> ' +
                            'Type: ' + d['side'] + ' <br> ' +
                            (d[yCat] ? 'Price: ' + $filter('number')(d['price'], 3) + ' <br> ' : '') +
                            (d['yield'] ? 'Yield: ' + $filter('number')(d['yield'], 3) + ' <br> ' : '') +
                            (d['quantity'].description ? 'Amount ($): ' + d['quantity'].description : '');
                    });

                var priceTip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html("Price: " + price);

                svg.call(tip);
                svg.call(priceTip);

                svg.append('rect')
                    .attr('width', width)
                    .attr('height', height)
                    .attr('fill', 'transparent');

                var xAxis = d3.axisBottom(x)
                    .tickValues(ChartUtil.getTicks(xAxisDomain))
                    .tickSize(-height)
                    .tickFormat(function (d) {
                        return $filter('mtvDateTime')(d);
                    })
                    .tickPadding(10);

                var yAxis = d3.axisLeft(y)
                    .ticks(6)
                    .tickSize(-width)
                    .tickPadding(10);

                var gX = svg.append('g')
                    .attr('class', 'axis axis--x')
                    .attr('transform', 'translate(0, ' + height + ')')
                    .call(xAxis);

                var gY = svg.append('g')
                    .attr('class', 'axis axis--y')
                    .call(yAxis);

                var objects = svg.append('svg')
                    .classed('objects', true)
                    .attr('width', width)
                    .attr('height', height);

                objects.append('svg:line')
                    .classed('axis-line hAxisLine', true)
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', width)
                    .attr('y2', 0);

                objects.append('svg:line')
                    .classed('axis-line vAxisLine', true)
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', 0)
                    .attr('y2', height);

                if (priceLineShouldBeShown) {
                    objects.append("svg:line")
                        .classed('price-line', true)
                        .attr("x1", 0)
                        .attr("x2", width)
                        .style("stroke", "rgb(125, 92, 92)")
                        .style("stroke-width", "3px")
                        .style("stroke-opacity", "0.7")
                        .on('mouseover', priceTip.show)
                        .on('mouseout', priceTip.hide);
                }

                objects.selectAll('.dot')
                    .data(data)
                    .enter()
                    .append('circle')
                    .classed('dot', true)
                    .classed('active', isActive)
                    .attr('r', radius)
                    .attr('transform', transform)
                    .attr('ng-click', onClick)
                    .style('fill', color)
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide);

                function isActive(d) {
                    return d.active;
                }

                function radius(d) {
                    return r(d.quantity.price);
                }

                function onClick(d) {
                    return 'select(' + d.index + ')';
                }

                function color(d) {
                    if (d.active) {
                        return 'blue';
                    }
                    return colorDomain(d['side']);
                }

                scope.select = function (index) {
                    if (readOnly) {
                        return;
                    }
                    tip.hide();
                    scope.data.data.forEach(function (d) {
                        d.active = false;
                    });

                    scope.data.data[index].active = true;
                };

                var legend = svg.selectAll('.legend')
                    .data(colorDomain.domain())
                    .enter()
                    .append('g')
                    .classed('legend', true)
                    .attr('transform', function (d, i) {
                        return 'translate(' + i * 60 + ', 0)';
                    });

                if (readOnly) {
                    svg.append('g')
                        .classed('chart-legend', true)
                        .append('text')
                        .attr('x', 0)
                        .attr('y', -16)
                        .attr('dy', '.45em')
                        .text('Trade History ' + capitalizeFirstLetter(scope.options.dataField) + ' to Date');
                }

                // TODO Make consistent enum for data field on the back-end and get rid of such transformations
                function capitalizeFirstLetter(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                }

                var legendCircleMargin = width - (hasUnknownSidePoints ? 200 : 110);

                legend.append('circle')
                    .attr('r', 7)
                    .attr('cx', legendCircleMargin)
                    .attr('cy', -15)
                    .attr('fill', colorDomain);

                legend.append('text')
                    .attr('x', legendCircleMargin + 10)
                    .attr('y', -16)
                    .attr('dy', '.45em')
                    .text(function (d) {
                        return d;
                    });

                var zoomBeh = d3.zoom()
                    .scaleExtent([0.9, 0.9])
                    .on('zoom', zoom);

                function zoom() {
                    var transform = d3.event.transform;
                    svg.selectAll('.dot')
                        .attr('transform', function (d) {
                            return 'translate(' + transform.applyX(x(d[xCat])) + ', ' + transform.applyY(y(d[yCat])) + ')';
                        });
                    svg.selectAll('.price-line')
                        .attr('transform', function () {
                            return 'translate(0, ' + transform.applyY(y(price)) + ')';
                        });
                    gX.call(xAxis.scale(transform.rescaleX(x)));
                    gY.call(yAxis.scale(transform.rescaleY(y)));
                }

                zoomBeh.scaleTo(svg, 0.9);

                svg.selectAll(".tick text")
                    .call(ChartUtil.wrapLabel, x);

                function transform(d) {
                    return 'translate(' + x(d[xCat]) + ',' + y(d[yCat]) + ')';
                }
            }

        }
    }
})();
