(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('priceCusip', priceCusip);

    priceCusip.$inject = ['$filter'];

    function priceCusip($filter) {
        return function (closestPeer) {
            if (!closestPeer || (!closestPeer.hasOwnProperty('price') && !closestPeer.hasOwnProperty('peerCusip8')) ||
                (closestPeer.price === null && closestPeer.peerCusip8 === null)) {
                return '-';
            }

            return $filter('priceNumber')(closestPeer.price.amount) + ' (' + closestPeer.peerCusip8 + ')';
        }
    }
})();
