(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('noAnimate', ['$animate', noAnimate]);

    function noAnimate($animate) {
        var directive = {
            restrict: 'A',
            priority: 1000,
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            $animate.enabled(element, false);
            scope.$watch(function() {
                $animate.enabled(element, false);
            })
        }
    }
})();
