(function () {
    'use strict';

    var header = {
        templateUrl: ['$attrs', function ($attrs) {
            return 'app/components/header/header.' + $attrs.state + '.template.html';
        }],
        controller: HeaderController,
        bindings: {
            report: '<',
            dpSettings: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('header', header);

    HeaderController.$inject = ['$attrs', 'Cusip'];

    function HeaderController($attrs, Cusip) {
        var vm = this;

        vm.showLogo = $attrs.showLogo !== undefined;
        vm.activeItem = 'best-execution-section';
        vm.setActive = setActive;
        vm.isActive = isActive;
        vm.getDataPermission = Cusip.getDataPermission;

        function setActive(item) {
            vm.activeItem = item;
        }

        function isActive(item){
            return item === vm.activeItem;
        }
    }
})();
