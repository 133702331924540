(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$uibModalInstance', 'entity', 'Group', 'User', 'Company'];

    function UserManagementDialogController ($uibModalInstance, entity, Group, User, Company) {
        var vm = this;

        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.errorMessages = {};

        if(vm.user.id === null) {
            vm.user.authorities = ['ROLE_DIRECT_ACCESS'];
        }

        Company.getActiveCompanies().query({size: 500}, function (result) {
            vm.companies = result;
        });

        Group.query(function (result) {
            vm.authorities = result;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(errors) {
            vm.isSaving = false;
            vm.errors = errors;
        }

        function save() {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.langKey = 'en';
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
