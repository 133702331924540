(function () {
    'use strict';

    var customDropdown = {
        templateUrl: 'app/components/common/custom-dropdown.template.html',
        controller: CustomDropdownController,
        bindings: {
            items: '<',
            selected: '<',
            onValueSelect: '&',
            label: '@',
            tooltip: '@',
            tooltipPlacement: '@'
        }
    };

    angular
        .module('mtvApp')
        .component('customDropdown', customDropdown);

    function CustomDropdownController() {
        var vm = this;

        vm.selectValue = selectValue;

        function selectValue(item) {
            vm.onValueSelect({ item: item });
        }
    }
})();
