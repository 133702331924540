(function () {
    'use strict';

    var checkboxToggleGroup = {
        templateUrl: 'app/standard/review-mode/components/checkbox-toggle-group/checkbox-toggle-group.template.html',
        controller: CheckboxToggleGroupController,
        bindings: {
            groupTitle: '<',
            options: '<',
            showHint: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('checkboxToggleGroup', checkboxToggleGroup);

    CheckboxToggleGroupController.$inject = ['$scope'];

    function CheckboxToggleGroupController($scope) {
        var vm = this;

        vm.isCollapsed = true;
        vm.toggleGroup = toggleGroup;
        refreshTotalAndTooltip(vm.options);

        function toggleGroup() {
            vm.isCollapsed = !vm.isCollapsed;
        }

        function getTotalChecked() {
            return vm.options.filter(function (option) {
                return option.checked;
            }).length;
        }

        $scope.$watch('$ctrl.options', refreshTotalAndTooltip, true);

        function refreshTotalAndTooltip(options) {
            vm.totalChecked = getTotalChecked();
            if (vm.totalChecked > 0) {
                vm.checkedFlagsTooltip = getCheckedFlagsTooltip(options);
            }
        }

        function getCheckedFlagsTooltip(options) {
            return options.filter(function (option) {
                return option.checked;
            }).map(function (option) {
                return option.label;
            }).join();
        }
    }
})();
