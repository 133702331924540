(function () {
    'use strict';

    var externalFooter = {
        templateUrl: 'app/components/external-footer/external-footer.template.html',
        controller: ExternalFooterController,
        bindings: {
        }
    };

    angular
        .module('mtvApp')
        .component('externalFooter', externalFooter);

    ExternalFooterController.$inject = [];

    function ExternalFooterController() {
        var vm = this;

        vm.currentDate = new Date();
    }
})();
