(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('closeOnScrollResize', ['$window', closeOnScrollResize]);

    function closeOnScrollResize($window) {
        var directive = {
            restrict: 'A',
            scope: {
                opened: '=closeOnScrollResize'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            element.closest('.tooltip-close-on-scroll').on('scroll', closeTooltip);

            angular.element($window).on('resize', closeTooltip);

            function closeTooltip() {
                scope.opened = false;
                scope.$parent.$digest();
            }
        }
    }
})();
