(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.trade-mode.search.approved', {
            abstract: true,
            params: {
                approvedHash: null,
                cusip: null,
                price: null,
                size: null,
                tradeSide: null,
                tradeId: null,
                key: null
            },
            views: {
                'search-container': {
                    templateUrl: 'app/standard/trade-mode/search/approved/approved.html',
                    controller: 'ApprovedController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
