(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('quantityTableValueFilter', quantityTableValueFilter)
        .filter('quantityChartValueFilter', quantityChartValueFilter)
        .filter('sideClassConverter', sideClassConverter);

    function sideClassConverter() {
        var obj = {
            Buy: 'text-orange',
            Sale: 'text-green',
            Unknown: 'text-grey'
        };

        return function (side) {
            return obj[side];
        }
    }

    quantityTableValueFilter.$inject = ['$filter'];

    function getQuantityObject(quantity, $filter) {
        if (endsWith('MM+', quantity)) {
            var mills = quantity.substring(0, quantity.indexOf("MM+"));
            return { price: mills * 1000000, description: '> $' + mills + 'M' };
        }
        return { price: quantity, description: $filter('number')(quantity, 3) };

        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }
    }

    function quantityTableValueFilter($filter) {
        return function (quantity) {
            return getQuantityObject(quantity, $filter).description;
        }
    }

    quantityChartValueFilter.$inject = ['$filter'];

    function quantityChartValueFilter($filter) {
        return function (quantity) {
            return getQuantityObject(quantity, $filter);
        }
    }
})();
