(function () {
    'use strict';

    // TODO: think about merging those filters
    angular
        .module('mtvApp')
        .filter('rtrsDataTypeFilter', rtrsDataTypeFilter)
        .filter('rtrsDataTypeConverter', rtrsDataTypeConverter)
        .filter('historyAmountConverter', historyAmountConverter);

    historyAmountConverter.$inject = ['$filter'];

    function rtrsDataTypeFilter() {
        return function (rtrsDataType) {
            var color;

            function isBuy() {
                return color = 'text-orange';
            }

            function isSale() {
                return color = 'text-green';
            }

            function isInterDealer() {
                return color = 'text-blue';
            }

            var rtrsDataTypes = {
                'INT_DEALER': isInterDealer,
                'BUY': isBuy,
                'SALE': isSale
            };

            return rtrsDataTypes[rtrsDataType]();
        }
    }

    function rtrsDataTypeConverter() {
        return function (rtrsDataType) {
            var type;

            function isInterDealer() {
                return type = 'Int Dealer';
            }

            function isSale() {
                return type = 'Sale';
            }

            function isBuy() {
                return type = 'Buy';
            }

            var rtrsDataTypes = {
                'INT_DEALER': isInterDealer,
                'BUY': isBuy,
                'SALE': isSale
            };

            return rtrsDataTypes[rtrsDataType]();
        }
    }

    function historyAmountConverter($filter) {
        return function (parTraded) {
            if (parTraded === 'MM+') {
                return {price: 5000000, description: '> $5M'};
            }
            return {price: parTraded, description: $filter('number')(parTraded, 3)};
        }
    }
})();
