(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('replaceUnderscoresFilter', replaceUnderscoresFilter);

    function replaceUnderscoresFilter() {
        return function (value) {
            return value.replace(/_/g, ' ');
        }
    }
})();
