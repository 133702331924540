(function () {
    'use strict';

    var traderComments = {
        templateUrl: 'app/components/trader-comments/trader-comments.template.html',
        controller: TraderCommentsController,
        bindings: {
            hash: '<',
            changeable: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('traderComments', traderComments);

    TraderCommentsController.$inject = ['$uibModal', 'Comments'];

    function TraderCommentsController($uibModal, Comments) {
        var vm = this;

        vm.isChangeable = isChangeable;
        vm.openModal = openModal;
        vm.comments = [];

        function isChangeable() {
            return vm.changeable;
        }

        function loadComments() {
            Comments.query({hash: vm.hash}).$promise
                .then(onLoadSuccess, onLoadError)
                .catch(onLoadError);

            function onLoadSuccess (result) {
                vm.comments = result;
            }

            function onLoadError(errors) {
            }
        }

        loadComments();

        function openModal() {
            $uibModal.open({
                templateUrl: 'app/components/trader-comments/modal/trader-comments-modal.template.html',
                controller: 'TraderCommentsModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: 'vertical-modal',
                resolve: {
                    entity: {
                        hash: vm.hash
                    }
                }
            }).closed.then(function () {
                loadComments();
            });
        }
    }
})();
