(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('Cusip', Cusip);

    Cusip.$inject = ['$http'];

    function Cusip($http) {
        function fetchDataPermissionSettings(userInput, searchHash, directAccessFlag) {
            return $http.get('api/trade-mode/data-permission-settings/' + userInput.cusip, {
                params: {
                    price: userInput.price.amount,
                    size: userInput.size,
                    tradeSide: userInput.tradeSide,
                    searchHash: searchHash,
                    directAccessFlag: directAccessFlag
                }
            });
        }

        function isView(comparisonView) {
            return function (uiView) {
                return uiView === comparisonView;
            }
        }

        function getDataPermission(comparisonDataPermission) {
            return function (dpSettings) {
                if(!dpSettings || !dpSettings.dataPermissionSettings) {
                    return {
                        value: false,
                        message: null
                    }
                }

                var filteredSettings = dpSettings.dataPermissionSettings.filter(function (s) {
                    return s.dataPermission === comparisonDataPermission;
                });

                if (filteredSettings.length === 0) {
                    return {
                        value: false,
                        message: null
                    }
                }
                return filteredSettings[0];
            }
        }

        return {
            fetchDataPermissionSettings: fetchDataPermissionSettings,
            isView: {
                corporate: isView('CORPORATE'),
                municipal: isView('MUNICIPAL')
            },
            getDataPermission: {
                toleranceChecks: getDataPermission('TOLERANCE_CHECKS'),
                bestExecution: getDataPermission('BEST_EXECUTION'),
                snapshotStats: getDataPermission('SNAPSHOT_STATISTICS'),
                marketData: getDataPermission('MARKET_DATA'),
                tradeHistory: getDataPermission('TRADE_HISTORY'),
                comparables: getDataPermission('COMPARABLES')
            }
        }
    }
})();
