(function () {
    'use strict';

    var searchForm = {
        templateUrl: 'app/standard/trade-mode/components/search-form/search-form.template.html',
        controller: SearchFormController
    };

    angular
        .module('mtvApp')
        .component('searchForm', searchForm);

    SearchFormController.$inject = ['$state', '$stateParams', '$rootScope', '$timeout', '$filter'];

    function SearchFormController($state, $stateParams, $rootScope, $timeout, $filter) {
        var vm = this;

        vm.init = init;
        vm.search = search;
        vm.invalidCusip = false;
        vm.tooltipText = tooltipText;
        vm.searchDisabled = searchDisabled;

        var tooltips = {
            cusip: {
                enabled: "Enter CUSIP-8 or CUSIP-9",
                disabled: "Security being transacted on"
            },
            price: {
                enabled: "Enter Price of security being transacted on",
                disabled: "Price of security being transacted on"
            },
            size: {
                enabled: "Enter the number of securities being transacted on",
                disabled: "Number of securities being transacted on"
            },
            side: {
                enabled: "Choose which side of the transaction you are on",
                disabled: "Side of the transaction you are on"
            }
        };

        init();

        $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            if(toState.name === 'standard.trade-mode.search.approve') {
                init();
            }
        });

        function init() {
            var tradeSide = $stateParams.tradeSide || '';
            $stateParams.tradeSide = tradeSide.toLowerCase();
            vm.searchForm = {
                cusip: $filter('limitTo')($stateParams.cusip, 9),
                tradeSide: $stateParams.tradeSide,
                price: $stateParams.price,
                size: $stateParams.size
            };
            $timeout(function(){
                vm.loaded = true;
            }, 0);
        }

        function search() {
            if ($rootScope.searchDisabled || !vm.searchForm.cusip) {
                return;
            }
            if (vm.searchForm.cusip.length >= 8) {
                vm.invalidCusip = false;
                vm.searchForm.price = formatNumber(vm.searchForm.price);
                vm.searchForm.size = formatNumber(vm.searchForm.size);
                $state.go('standard.trade-mode.search.approve', angular.copy(vm.searchForm), { reload: true });
            } else {
                vm.invalidCusip = true;
            }
        }

        function tooltipText(type) {
            if(angular.element('input.' + type).attr('disabled') === 'disabled') {
                return tooltips[type]['disabled'];
            }
            return tooltips[type]['enabled'];
        }

        //TODO: remove when validation will be added
        function formatNumber(input){
            if (!input){
                return '';
            }
            return input.match(/\d+(\.\d+)?/) ? parseFloat(input) : '';
        }

        function searchDisabled() {
            return $rootScope.searchDisabled;
        }
    }
})();
