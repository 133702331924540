(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('Company', Company);

    Company.$inject = ['$resource'];

    function Company($resource) {
        function getAllCompanies(){
            return $resource('api/companies', {}, {
                'query': {
                    method: 'GET',
                    isArray: true
                }
            });
        }

        function getActiveCompanies(){
            return $resource('api/companies/active', {}, {
                'query': {
                    method: 'GET',
                    isArray: true
                }
            });
        }


        function getAllUsers(){
            return $resource('api/companies/users', {}, {
                'query': {
                    method: 'GET',
                    isArray: true
                }
            });
        }

        return {
            getAllCompanies: getAllCompanies,
            getActiveCompanies: getActiveCompanies,
            getAllUsers: getAllUsers
        }
    }
})();
