(function() {
    'use strict';

    angular
        .module('mtvApp')
        .controller('MtvConfigurationController', MtvConfigurationController);

    MtvConfigurationController.$inject = ['$filter','MtvConfigurationService'];

    function MtvConfigurationController (filter, MtvConfigurationService) {
        var vm = this;

        vm.allConfiguration = null;
        vm.configuration = null;

        MtvConfigurationService.get().then(function(configuration) {
            vm.configuration = configuration;
        });
        MtvConfigurationService.getEnv().then(function (configuration) {
            vm.allConfiguration = configuration;
        });
    }
})();
