(function () {
    'use strict';

    var marketDataDetails = {
        templateUrl: 'app/components/market-data-details/market-data-details.template.html',
        controller: MarketDataDetailsController,
        bindings: {
            data: '=',
            marketType: '<',
            filteringMarketType: '=',
            bwIncluded: '='
        }
    };

    angular
        .module('mtvApp')
        .component('marketDataDetails', marketDataDetails);

    MarketDataDetailsController.$inject = ['$scope', '$filter', '$attrs'];

    function MarketDataDetailsController($scope, $filter, $attrs) {
        var readOnly = $attrs.readOnly !== undefined;

        var vm = this;
        vm.showControls = $attrs.showControls !== undefined;
        vm.isMarketDataType = isMarketDataType;
        vm.changeMarketData = changeMarketData;
        vm.updateMarketDataType = updateMarketDataType;
        vm.select = select;
        vm.marketDataType = vm.marketType;
        vm.orderTypes = [];

        init();

        function init() {
            if (!vm.showControls) {
                return;
            }
            $scope.$watch('$ctrl.data', updateMarketDataListener, true);
            $scope.$watchGroup(['$ctrl.bwIncluded', '$ctrl.marketDataType'], function(marketType) {
                vm.changeMarketData(marketType);
            });
        }

        function updateMarketDataType(type) {
            vm.marketDataType = type;
            vm.filteringMarketType = type;
        }

        function isMarketDataType(type) {
            return vm.marketDataType === type;
        }

        function select(item) {
            if(!vm.cachedData || readOnly) {
                return;
            }
            vm.cachedData.forEach(function(d) {
                d.active = false;
            });
            item.active = true;
        }

        function updateMarketDataListener(value) {
            if (!value) {
                return;
            }

            deselectCachedItems(value);

            if (vm.cachedData) {
                return;
            }
            vm.cachedData = angular.copy(value);
            changeMarketData(vm.marketType);
        }

        function deselectCachedItems(value) {
            for(var i = 0; i < value.length; i++) {
                if (value[i].active) {
                    select(value[i]);
                    return;
                }
            }
        }

        function changeMarketData() {
            vm.orderTypes = getOrderTypes(vm.bwIncluded, vm.marketDataType);
            vm.data = $filter('filter')(vm.cachedData, filterDataByOrderType);
        }

        function filterDataByOrderType(value) {
            return vm.orderTypes.indexOf(value.orderType) > -1;
        }

        function getOrderTypes(bwIncluded, orderType) {
            var orderTypes = getConfigByOrderType(orderType);
            if (bwIncluded) {
                orderTypes.push('BID_WANTED');
            }

            return orderTypes;
        }

        function getConfigByOrderType(type) {
            var orderTypes = {
                Bid: ['SITUATION_BID', 'BW_BID'],
                Offering: ['OFFERING'],
                All: ['SITUATION_BID', 'OFFERING', 'BW_BID']
            };

            return orderTypes[type];
        }
    }
})();
