(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('read-only.report', {
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                approvedHash: null,
                prevStateParams: null
            },
            views: {
                'read-only-container': {
                    template:'<report-info report="vm.report"></report-info>'
                },
                'header-container': {
                    template: '<header state="read-only" report="vm.report" show-logo></header>'
                }
            }
        });
    }
})();
