(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('Snapshot', Snapshot);

    Snapshot.$inject = ['$resource'];

    function Snapshot($resource) {
        var service = $resource('api/snapshot/:hash', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.snapshot = migrateSnapshot(JSON.parse(data.snapshot.content));
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformResponse: function (data) {
                    return {hash: data};
                }
            }
        });

        function migrateSnapshot(snapshot) {
            if (snapshot.finraData) {
                return snapshot;
            }

            if(Array.isArray(snapshot.comparableData)) {
                var array = snapshot.comparableData;
                snapshot.comparableData = {};
                snapshot.comparableData.comparables = array;
            }

            if(snapshot.historyData) {
                snapshot.rtrsData = snapshot.historyData;

                angular.forEach(snapshot.rtrsData, function (item) {
                    item.rtrsDataType = item.historyDataType;
                    item.tradeDateTime = item.tradeDate || item.tradeDateTime;
                    delete item.historyDataType;
                    delete item.tradeDate;
                });

                delete snapshot.historyData;
            }

            return snapshot;
        }

        return service;
    }
})();
