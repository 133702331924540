(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.trade-mode.search', {
            abstract: true,
            url: 'search/:cusip?tradeSide,price,size,tradeId',
            params: {
                tradeSide: 'buy',
                price: null,
                size: null,
                tradeId: null
            },
            data: {
                authorities: []
            },
            views: {
                'main-container@standard': {
                    templateUrl: 'app/standard/trade-mode/search/search.html',
                    controller: 'SearchController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
