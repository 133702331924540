(function () {
    'use strict';

    var corporateData = {
        templateUrl: 'app/components/corporate-data/corporate-data.template.html',
        controller: CorporateDataController,
        bindings: {
            userInput: '<',
            dpSettings: '<',
            toleranceChecks: '<',
            bestExecutionStatistics: '<',
            marketData: '<',
            marketType: '<',
            filteringMarketType: '=',
            bwIncluded: '=',
            finraData: '<',
            filteredFinraData: '<',
            onDataFieldChange: '&',
            onFilterValueChange: '&'
        }
    };

    angular
        .module('mtvApp')
        .component('corporateData', corporateData);

    CorporateDataController.$inject = ['Cusip'];

    function CorporateDataController(Cusip) {
        var vm = this;

        vm.reportingPartyTypeItems = [
            { value: 'All', filterName: 'reportingPartyType' },
            { value: 'Broker/Dealer', filterName: 'reportingPartyType' },
            { value: 'Alternative Trading System', filterName: 'reportingPartyType' }
        ];
        vm.contraPartyTypeItems = [
            { value: 'All', filterName: 'contraPartyType' },
            { value: 'Broker/Dealer', filterName: 'contraPartyType' },
            { value: 'Customer', filterName: 'contraPartyType' },
            { value: 'Non-member affiliate', filterName: 'contraPartyType' },
            { value: 'Alternative Trading System', filterName: 'contraPartyType' }
        ];
        vm.dataFieldItems = [
            { value: 'Price to Date', dataField: 'price' },
            { value: 'Yield to Date', dataField: 'yield' }
        ];

        vm.marketDataPermission = Cusip.getDataPermission.marketData(vm.dpSettings);
        vm.tradeHistoryPermission = Cusip.getDataPermission.tradeHistory(vm.dpSettings);
        vm.bestExecutionPermission = Cusip.getDataPermission.bestExecution(vm.dpSettings);
        vm.setDataField = setDataField;
        vm.setFilterValue = setFilterValue;
        vm.select = select;

        function setDataField(item) {
            vm.onDataFieldChange({ item: item });
        }

        function setFilterValue(item) {
            vm.onFilterValueChange({ item: item });
        }

        function select(item) {
            vm.filteredFinraData.forEach(function(d) {
                d.active = false;
            });
            item.active = true;
        }
    }
})();
