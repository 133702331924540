(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('Comments', Comments);

    Comments.$inject = ['$resource'];

    function Comments($resource) {
        var service = $resource('api/snapshot/:hash/comments', {}, {
            'query': {method: 'GET', isArray: true},
            'save': {method: 'POST'}
        });

        return service;
    }
})();
