(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('priceNumber', priceNumber);

    priceNumber.$inject = ['$filter'];

    function priceNumber($filter) {
        return function (number, fraction) {
            fraction = fraction || 3;
            if (number === undefined || number === null) {
                return number;
            }
            if(number.toString().indexOf(".") === -1){
                return $filter('number')(number, fraction);
            }

            var value = number.toString();
            var filteredValue = value.slice(0, (value.indexOf(".")) + (fraction + 1));

            return $filter('number')(filteredValue, fraction)
        }
    }
})();
