(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('obligor', obligor);

    obligor.$inject = ['$filter'];

    function obligor($filter) {
        return function (value, obligorSubstringLength) {
            if (!value) {
                return '';
            }

            var obligor = value.name;
            if(shouldShowDots(obligor, obligorSubstringLength)) {
                obligor = obligor.substring(0, obligorSubstringLength) + '...';
            }

            return formatObligor(obligor, value.stateAbr, value.fyeDate);
        };

        function shouldShowDots(obligor, obligorSubstringLength) {
            return obligor.length - obligorSubstringLength > 4;
        }

        function formatObligor(obligor, stateAbr, fyeDate) {
            var obligorNameState = obligor + ' (' + stateAbr + ')';
            var lastAnnualFinancialDateValue = '(N/A)';

            if (fyeDate) {
                lastAnnualFinancialDateValue = '(' + $filter('mtvDate')(fyeDate) + ')';
            }

            return obligorNameState + ' - ' + lastAnnualFinancialDateValue;
        }
    }
})();
