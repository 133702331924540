(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('Review', Review);

    Review.$inject = ['$resource'];

    function Review($resource) {

        function getSummary(){
            return {
                'review-mode': $resource('api/review-mode/summary', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                }),
                'trader-review-mode': $resource('api/review-mode/trader/summary', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                })
            };
        }

        function getDetails(){
            return {
                'review-mode': $resource('api/review-mode/summary/details', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                }),
                'trader-review-mode': $resource('api/review-mode/trader/summary/details', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                })
            };
        }

        return {
            getSummary: getSummary(),
            getDetails: getDetails()
        };
    }
})();
