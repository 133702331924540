(function() {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.review-mode', {
            abstract: true,
            url: 'review',
            data: {
                authorities: ['ROLE_REVIEW']
            },
            views: {
                'header-container': {
                    template: '<header state="review"></header>'
                },
                'main-container': {
                    templateUrl: 'app/standard/review-mode/review-mode.html',
                    controller: 'ReviewModeController',
                    controllerAs: 'vm'
                },
                'side-panel-container': {
                    template: '<control-form></control-form>'
                }
            }
        });
    }
})();
