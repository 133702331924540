(function () {
    'use strict';

    var userFlags = {
        templateUrl: ['$element', '$attrs', function ($element, $attrs) {
            var state = $attrs.readOnly !== undefined ? 'read-only.' : '';
            return 'app/components/user-flags/user-flags.' + state + 'template.html';
        }],
        controller: UserFlagsController,
        bindings: {
            checkedIds: '=',
            viewType: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('userFlags', userFlags);

    UserFlagsController.$inject = ['$scope', '$http'];

    function UserFlagsController($scope, $http) {
        var vm = this;
        var updated;

        init();

        $scope.$watch('$ctrl.checkedIds', function (newValue) {
            updateData(newValue, 'flags');
        }, true);
        $scope.$watch('$ctrl.userFlags', function (newValue) {
            updateData(newValue, 'checkedIds');
        }, true);

        function init() {
            updated = true;
            $http.get('api/trade-mode/user-flags', { params: { uiView: vm.viewType } }).then(function (result) {
                vm.userFlags = result.data;
                updateData(vm.checkedIds, 'flags');
            });
        }

        function updateData(newValue, type) {
            if(updated) {
                updated = false;
                return;
            }

            update(type)(newValue);

            function update(type) {
                var updateFns =  {
                    flags: updateFlags,
                    checkedIds: updateCheckedIds
                };

                return updateFns[type];
            }
        }

        function updateFlags(checkedIds) {
            if (!vm.userFlags) {
                return;
            }
            vm.userFlags.forEach(function (item) {
                item['checked'] = checkedIds && checkedIds.indexOf(item.id) !== -1;
            });
        }

        function updateCheckedIds(userFlags) {
            if(!userFlags) {
                return;
            }

            vm.checkedIds = userFlags.filter(function (item) {
                return item.checked;
            }).map(function (item) {
                return item.id;
            });
        }
    }
})();
