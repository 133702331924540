(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('direct-access', {
            parent: 'app',
            abstract: true,
            url: '/',
            data: {
                authorities: []
            },
            params: {
                cusip: null,
                price: null,
                size: null,
                tradeSide: null,
                tradeId: null,
                key: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/standard/standard.html',
                    controller: 'StandardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                directAccess: ['$state', '$stateParams', 'Auth',
                    function ($state, $stateParams, Auth) {
                        return Auth.login({
                            key: $stateParams.key
                        }, null).then(function (data) {
                            if(!areMandatoryFieldsPresent()){
                                $state.go('login', {customErrorMessage: 'Invalid url'}, {reload: true});
                            }
                        }).catch(function (error) {
                            $state.go('login', {customErrorMessage: error.data.message}, {reload: true});
                        });

                        function areMandatoryFieldsPresent() {
                            return $stateParams.cusip && $stateParams.tradeSide;
                        }
                    }
                ]
            }
        });
    }
})();
