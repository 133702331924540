(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('showValidation', showValidation);

    showValidation.$inject = ['$timeout'];

    function showValidation($timeout) {
        var directive = {
            restrict: 'A',
            require: 'form',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            element.find('.form-group').each(function () {
                    var $formGroup = angular.element(this);
                    var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model]');

                    if (!$inputs.length) {
                        return;
                    }
                    $inputs.each(function () {
                        var $input = angular.element(this);
                        if ($input[0].multiple) {
                            return;
                        }
                        scope.$watchGroup([function () {
                                return $input.val();
                            }, function () {
                                return $input.hasClass('ng-invalid') && $input.hasClass('ng-dirty');
                            }], function () {
                                $timeout(function () {
                                    var isInvalid = $input.hasClass('ng-invalid') && $input.hasClass('ng-dirty');
                                    $formGroup.toggleClass('has-error', isInvalid);
                                });
                            });
                    });
                }
            );
        }
    }
})();
