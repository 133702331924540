(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('direct-access.trade-mode.search', {
            abstract: true,
            url: 'direct-access?cusip,price,size,tradeSide,tradeId,key',
            data: {
                authorities: []
            },
            views: {
                'main-container@direct-access': {
                    templateUrl: 'app/standard/trade-mode/search/search.html',
                    controller: 'SearchController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
