(function() {
    'use strict';

    angular
        .module('mtvApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = [];

    function HomeController () {
        var vm = this;
    }
})();
