(function() {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.trade-mode.home', {
            url: '',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'header-container@standard': {
                    template: '<header state="home"></header>'
                },
                'main-container@standard': {
                    templateUrl: 'app/standard/trade-mode/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
