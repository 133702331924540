(function () {
    'use strict';

    var comparablesFilter = {
        templateUrl: 'app/components/comparables/comparables-filter.template.html',
        controller: ComparablesFilterController,
        bindings: {
            data: '=',
            marketType: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('comparablesFilter', comparablesFilter);

    ComparablesFilterController.$inject = ['$scope', '$filter'];

    function ComparablesFilterController($scope, $filter) {
        var vm = this;

        vm.clear = clear;

        $scope.$watch('$ctrl.data.comparables', updateLimits);
        $scope.$watch('$ctrl.filter', filter, true);

        function clear() {
            updateLimits(vm.data.comparables);
        }

        function updateLimits(data) {
            if (!data || data.length === 0) {
                return;
            }

            for (var key in vm.filter) {
                if (!vm.filter.hasOwnProperty(key)) {
                    continue;
                }
                var item = vm.filter[key];
                updateFieldLimits()[item.type](data, item);
            }
        }

        function filter(filterValue) {
            if (!filterValue || !vm.data || !vm.data.comparables) {
                return;
            }
            vm.data.comparables.forEach(function (item) {
                item['filtered'] = true;
            });

            for (var key in filterValue) {
                if (!filterValue.hasOwnProperty(key)) {
                    continue;
                }
                var item = filterValue[key];
                filterDataByField()[item.type](item)
            }
        }

        function filterDataByField() {
            return {
                number: filterDataByNumberField,
                date: filterDataByDateField
            }
        }

        function updateFieldLimits() {
            return {
                number: updateNumberFieldLimits,
                date: updateDateFieldLimits
            }
        }

        function filterDataByNumberField(filter) {
            vm.data.comparables.forEach(function (item) {
                if (!item['filtered']) {
                    return;
                }

                var value = fieldValue(item, filter.fieldName);
                if (value === null || value === undefined) {
                    return;
                }
                item['filtered'] = value >= filter.minValue
                    && value <= filter.maxValue;

                if(!item['filtered']) {
                    item['selected'] = false;
                }
            });
        }

        function filterDataByDateField(filter) {
            vm.data.comparables.forEach(function (item) {
                if (!item['filtered']) {
                    return;
                }

                var value = $filter('mtvDate')(fieldValue(item, filter.fieldName), 'YYYY-MM-DD');
                if (!value) {
                    return;
                }
                item['filtered'] = value >= filter.minValue
                    && value <= filter.maxValue;

                if(!item['filtered']) {
                    item['selected'] = false;
                }
            });
        }

        function updateNumberFieldLimits(comparables, filter) {
            var values = comparables.map(function (item) {
                return fieldValue(item, filter.fieldName);
            }).filter(function (val) {
                return val || val === 0;
            });

            if(values.length === 0) {
                return;
            }

            setBoundaries(filter, values);
        }

        function setBoundaries(filter, values) {
            var scale = Math.pow(10, filter.options.precision);
            filter.options.floor = Math.floor(parseFloat(ss.min(values)) * scale) / scale;
            filter.options.ceil = Math.ceil(parseFloat(ss.max(values)) * scale) / scale;
            filter.minValue = filter.options.floor;
            filter.maxValue = filter.options.ceil;
        }

        function updateDateFieldLimits(comparables, filter) {
            var dateValues = comparables.map(function (item) {
                return $filter('mtvDate')(fieldValue(item, filter.fieldName), 'YYYY-MM-DD');
            }).filter(function (val) {
                return val;
            });

            if(dateValues.length === 0) {
                return;
            }

            var limits = {
                min: ss.min(dateValues),
                max: ss.max(dateValues)
            };

            var dates = [limits.min];
            while (true) {
                var tomorrow = moment(dates[dates.length - 1])
                    .add(filter.stepInDays, 'days')
                    .format('YYYY-MM-DD');
                dates.push(tomorrow);
                if (tomorrow >= limits.max) {
                    break;
                }
            }

            filter.minValue = dates[0];
            filter.maxValue = dates[dates.length-1];
            filter.options.stepsArray = dates;
        }

        function fieldValue(object, composeField) {
            var val = object;
            composeField.split('.').forEach(function (fieldName) {
                if(!val) {
                    return null;
                }
                val = val[fieldName];
            });
            return val;
        }

        vm.filter = {
            coupon: {
                type: 'number',
                fieldName: 'securityDetails.coupon',
                minValue: 0,
                maxValue: 0,
                options: {
                    floor: 0,
                    ceil: 0,
                    step: 0.001,
                    precision: 3,
                    translate: function (number) {
                        if (number !== null) {
                            return number.toFixed(3);
                        }
                        return '';
                    }
                }
            },
            maturityDate: {
                type: 'date',
                fieldName: 'securityDetails.maturityDate',
                minValue: 0,
                maxValue: 0,
                stepInDays: 1,
                options: {
                    stepsArray: [],
                    translate: function (date) {
                        if (date !== null)
                            return $filter('mtvDate')(date, 'MM/DD/YYYY');
                        return '';
                    }
                }
            },
            lastOpenTradePrice: {
                type: 'number',
                fieldName: 'marketDataDetails.lastOpenTradePrice.amount',
                minValue: 0,
                maxValue: 0,
                options: {
                    floor: 0,
                    ceil: 0,
                    step: 0.001,
                    precision: 3,
                    translate: function (number) {
                        if (number !== null) {
                            return $filter('currency')(number, '$', 3);
                        }
                        return '';
                    }
                }
            },
            lastClosedTradePrice: {
                type: 'number',
                fieldName: 'marketDataDetails.lastClosedTradePrice.amount',
                minValue: 0,
                maxValue: 0,
                options: {
                    floor: 0,
                    ceil: 0,
                    step: 0.001,
                    precision: 3,
                    translate: function (number) {
                        if (number !== null) {
                            return $filter('currency')(number, '$', 3);
                        }
                        return '';
                    }
                }
            },
            weight: {
                type: 'number',
                fieldName: 'weight',
                minValue: 0,
                maxValue: 0,
                options: {
                    floor: 0,
                    ceil: 0,
                    step: 0.001,
                    precision: 3,
                    translate: function (number) {
                        if (number !== null) {
                            return number.toFixed(3);
                        }
                        return '';
                    }
                }
            }
        };
    }
})();
