(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('customErrors', customErrors);


    function customErrors() {
        var directive = {
            restrict: 'A',
            require: 'form',
            link: linkFunc,
            scope: {
                form: '=customErrors',
                errors: '<',
                messages: '='
            }
        };

        return directive;

        function linkFunc(scope) {
            scope.$watch('errors', processFormErrors);

            function processFormErrors(errors) {
                if (!errors || !errors.data) {
                    return;
                }
                angular.forEach(errors.data['fieldErrors'], processError)
            }

            function processError(item) {
                var field = item['field'];

                scope.form[field].$setValidity('custom', false);
                scope.messages[field] = item['message'];

                eraseErrorOnFieldChange(field);
            }

            function eraseErrorOnFieldChange(field) {
                var unbindWatch = scope.$watch(function () {
                    return scope.form[field].$modelValue;
                }, function (newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }
                    scope.form[field].$setValidity('custom', true);
                    unbindWatch();
                });
            }
        }
    }
})
();
