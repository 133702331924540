(function () {
    'use strict';

    angular
        .module('mtvApp')
        .controller('ReviewModeController', ReviewModeController);

    ReviewModeController.$inject = ['$state'];

    function ReviewModeController($state) {
        var vm = this;
        vm.isReviewMode = $state.current.name.indexOf('standard.review-mode') !== -1;
        vm.changeView = changeView;
        var parentState = getParentState();

        vm.isActive = function (stateName) {
            return $state.current.name.indexOf(stateName) !== -1;
        };

        function getParentState() {
            return $state.current.name.split('.').slice(0, -1).join('.');
        }

        function changeView(stateName) {
            $state.go(parentState + '.' + stateName,
                angular.extend($state.params, {
                    'activePosition': undefined,
                    'outerPage': undefined,
                    'innerPage': undefined
                }));
        }
    }
})();
