(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('mmTransformationFilter', mmTransformationFilter);

    mmTransformationFilter.$inject = ['$filter'];

    function mmTransformationFilter($filter) {
        return function (value) {
            if (value === 'MM+') {
                return '> $5M';
            }
            return $filter('number')(value, 3);
        }
    }
})();
