/* globals $ */
(function () {
    'use strict';

    angular
        .module('mtvApp')
        .directive('marketDataChart', marketDataChart);

    marketDataChart.$inject = ['$filter', '$compile', 'ChartUtil'];

    function marketDataChart($filter, $compile, ChartUtil) {
        var directive = {
            replace: true,
            restrict: 'E',
            template: '<div class="chart">' +
            '<div id="scatter"></div>' +
            '</div>',
            scope: {
                data: '=',
                tradeSide: '<',
                price: '<'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var readOnly = attrs.readOnly !== undefined;
            var priceLineShouldBeShown, price;

            var margin = {top: 30, right: 30, bottom: 50, left: 50},
                outerWidth = 570,
                outerHeight = 380,
                width = outerWidth - margin.left - margin.right,
                height = outerHeight - margin.top - margin.bottom;

            var svg = d3.select('#scatter')
                .append('svg')
                .attr('width', outerWidth)
                .attr('height', outerHeight)
                .append('g')
                .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

            function prepareData(data) {
                var chartData = angular.copy(data);
                chartData.forEach(function (d, index) {
                    d.index = index;
                });

                chartData = chartData.filter(function (el) {
                    return el.orderType !== 'BID_WANTED';
                });

                chartData.forEach(function (d) {
                    d.orderDate = moment($filter('mtvDate')(d.orderDate, 'YYYY-MM-DD HH:mm:ss')).toDate();
                    d.orderType = $filter('orderTypeConverter')(d.orderType);
                });

                return $filter('orderBy')(chartData, function (d) {
                    return d.active;
                });
            }

            scope.$watch('data', function (newVal) {
                if (!newVal) {
                    return;
                }

                svg.selectAll('*').remove();
                var graphData = prepareData(newVal);
                var parsedPrice = parsePrice(scope.price);
                priceLineShouldBeShown = priceIsNumber(parsedPrice) && newVal.length > 0;
                price = priceLineShouldBeShown
                    ? parsedPrice
                    : null;
                render(graphData, scope.tradeSide);
                $compile(element)(scope);
            }, true);

            function priceIsNumber(price) {
                return price !== null
                    && !isNaN(price)
                    && angular.isNumber(price);
            }

            function parsePrice(value) {
                return value !== null
                    ? parseFloat(value.amount)
                    : null;
            }

            function render(data) {
                var xCat = 'orderDate',
                    yCat = 'price',
                    rCat = 'amount';

                var xAxisDomain = normalizeDomain({type: 'DATE', data: data, field: xCat});
                var yAxisDomain = normalizeDomain({data: data, field: yCat});

                function yAxisContainsValues() {
                    return !isNaN(yAxisDomain[0])
                        && !isNaN(yAxisDomain[1]);
                }

                priceLineShouldBeShown = priceLineShouldBeShown && yAxisContainsValues();

                if (priceLineShouldBeShown) {
                    if (yAxisDomain[0] > price) {
                        yAxisDomain[0] = price - getAdditionalTickSize(yAxisDomain[1], price)
                    }
                    if (yAxisDomain[1] < price) {
                        yAxisDomain[1] = price + getAdditionalTickSize(yAxisDomain[0], price)
                    }
                }
                function getAdditionalTickSize(boundary, value) {
                    return Math.abs(boundary - value) / 10;
                }

                var rDomain = d3.extent(data, function (d) {
                    return d[rCat];
                });

                function normalizeDomain(config) {
                    var types = {
                        NUMBER: getNumberAxisDomain,
                        DATE: getDateAxisDomain
                    };

                    return types[config.type || 'NUMBER'](config);
                }

                function getNumberAxisDomain(config) {
                    var range = config.range || 0.05;
                    var values = d3.extent(config.data, function (d) {
                        return d[config['field']];
                    });

                    if (values[0] === values[1]) {
                        return [(1 - range) * values[0], (1 + range) * values[1]];
                    }
                    return values;
                }

                function getDateAxisDomain(config) {
                    var values = d3.extent(config.data, function (d) {
                        return d[config['field']];
                    });

                    if (values[0] === values[1]) {
                        return [moment(values[0]).add(-1, 'days').toDate(), moment(values[1]).add(1, 'days').toDate()];
                    }
                    return values;
                }

                var x = d3.scaleTime()
                    .range([0, width])
                    .domain(xAxisDomain);

                var y = d3.scaleLinear()
                    .range([height, 0])
                    .domain(yAxisDomain);

                var r = d3.scaleLinear()
                    .range([5, 10])
                    .domain(rDomain);

                var colorDomain = d3.scaleOrdinal()
                    .domain(['Bid', 'Offer'])
                    .range(['#ff8227', '#2bbd2b']);

                var radiusScale = d3.scaleLinear()
                    .domain([0, yAxisDomain[1]])
                    .range([4, 20]);

                var tip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html(function (d) {
                        return 'Date/Time: ' + $filter('mtvDateTime')(d[xCat]) + ' <br> ' +
                            'Type: ' + d['orderType'] + ' <br> ' +
                            (d[yCat] ? 'Price: ' + $filter('number')(d[yCat], 3) + ' <br> ' : '') +
                            (d['yield'] ? 'Yield: ' + $filter('number')(d['yield'], 3) + ' <br> ' : '') +
                            (d['amount'] ? 'Amt: ' + d['amount'] : '');
                    });

                var priceTip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html("Price: " + price);

                svg.call(tip);
                svg.call(priceTip);

                svg.append('rect')
                    .attr('width', width)
                    .attr('height', height)
                    .attr('fill', 'transparent');

                var xAxis = d3.axisBottom(x)
                    .tickValues(ChartUtil.getTicks(xAxisDomain))
                    .tickSize(-height)
                    .tickFormat(function (d) {
                        return $filter('mtvDateTime')(d)
                    })
                    .tickPadding(10);

                var yAxis = d3.axisLeft(y)
                    .ticks(6)
                    .tickSize(-width)
                    .tickPadding(10);

                var gX = svg.append('g')
                    .attr('class', 'axis axis--x')
                    .attr('transform', 'translate(0, ' + height + ')')
                    .call(xAxis);

                var gY = svg.append('g')
                    .attr('class', 'axis axis--y')
                    .call(yAxis);

                var objects = svg.append('svg')
                    .classed('objects', true)
                    .attr('width', width)
                    .attr('height', height);

                objects.append('svg:line')
                    .classed('axis-line hAxisLine', true)
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', width)
                    .attr('y2', 0);

                objects.append('svg:line')
                    .classed('axis-line vAxisLine', true)
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', 0)
                    .attr('y2', height);

                if (priceLineShouldBeShown) {
                    objects.append("svg:line")
                        .classed('price-line', true)
                        .attr("x1", 0)
                        .attr("x2", width)
                        .style("stroke", "rgb(125, 92, 92)")
                        .style("stroke-width", "3px")
                        .style("stroke-opacity", "0.7")
                        .on('mouseover', priceTip.show)
                        .on('mouseout', priceTip.hide);
                }

                objects.selectAll('.dot')
                    .data(data)
                    .enter()
                    .append('circle')
                    .classed('dot', true)
                    .classed('active', isActive)
                    .attr('r', radius)
                    .attr('transform', transform)
                    .attr('ng-click', onClick)
                    .style('fill', color)
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide);

                function isActive(d) {
                    return d.active;
                }

                function radius(d) {
                    return r(d[rCat]);
                }

                function onClick(d) {
                    return 'select(' + d.index + ')';
                }

                function color(d) {
                    if (d.active) {
                        return 'blue';
                    }
                    return colorDomain(d['orderType']);
                }

                scope.select = function (index) {
                    if (readOnly) {
                        return;
                    }

                    tip.hide();
                    scope.data.forEach(function (d) {
                        d.active = false;
                    });

                    scope.data[index].active = true;
                };

                var legend = svg.selectAll('.legend')
                    .data(colorDomain.domain())
                    .enter()
                    .append('g')
                    .classed('legend', true)
                    .attr('transform', function (d, i) {
                        return 'translate(' + i * 80 + ', 0)';
                    });

                svg.append('g')
                    .classed('chart-legend', true)
                    .append('text')
                    .attr('x', 0)
                    .attr('y', -16)
                    .attr('dy', '.45em')
                    .text('Market Activity Price to Date');

                var legendCircleMargin = width - 130;

                legend.append('circle')
                    .attr('r', 7)
                    .attr('cx', legendCircleMargin)
                    .attr('cy', -15)
                    .attr('fill', colorDomain);

                legend.append('text')
                    .attr('x', legendCircleMargin + 10)
                    .attr('y', -16)
                    .attr('dy', '.45em')
                    .text(function (d) {
                        return d;
                    });

                svg.call(d3.drag().on('start drag end', function () {
                }));

                var zoomBeh = d3.zoom()
                    .scaleExtent([0.9, 0.9])
                    .on('zoom', zoom);

                function zoom() {
                    var transform = d3.event.transform;
                    svg.selectAll('.dot')
                        .attr('transform', function (d) {
                            return 'translate(' + transform.applyX(x(d[xCat])) + ', ' + transform.applyY(y(d[yCat])) + ')';
                        });
                    svg.selectAll('.price-line')
                        .attr('transform', function () {
                            return 'translate(0, ' + transform.applyY(y(price)) + ')';
                        });
                    gX.call(xAxis.scale(transform.rescaleX(x)));
                    gY.call(yAxis.scale(transform.rescaleY(y)));
                }

                zoomBeh.scaleTo(svg, 0.9);

                svg.selectAll(".tick text")
                    .call(ChartUtil.wrapLabel, x);

                function transform(d) {
                    return 'translate(' + x(d[xCat]) + ',' + y(d[yCat]) + ')';
                }
            }
        }
    }
})();
