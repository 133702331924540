(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('ChartUtil', ChartUtil);

    function ChartUtil() {

        var service = {
            wrapLabel: wrapLabel,
            getTicks: getTicks
        };

        return service;

        function getTicks(xAxisDomain) {
            var dates = [];
            var millisBetween = moment(xAxisDomain[1]).diff(moment(xAxisDomain[0]), 'milliseconds');
            for (var i = 0; i < 5; i++) {
                dates.push(moment(xAxisDomain[0]).add(millisBetween / 4 * i, 'milliseconds'));
            }
            return dates;
        }


        function wrapLabel(text) {
            text.each(function () {
                if (d3.select(this).text().match(/^(\S+)\s(.*)/) != null) {
                    var text = d3.select(this),
                        words = text.text()
                            .match(/^(\S+)\s(.*)/)
                            .slice(1)
                            .reverse(),
                        word,
                        line = [],
                        lineNumber = 0,
                        lineHeight = 1.1,
                        y = text.attr('y'),
                        dy = parseFloat(text.attr('dy')),
                        tspan = text.text(null)
                            .append('tspan')
                            .attr('x', 0)
                            .attr('y', y)
                            .attr('dy', dy + 'em');

                    while (word = words.pop()) {
                        line.push(word);
                        tspan.text(line.join(' '));
                        line.pop();
                        tspan.text(line.join(' '));
                        line = [word];
                        tspan = text.append('tspan')
                            .attr('x', 0)
                            .attr('y', y)
                            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                            .text(word);
                    }
                }
            });
        }
    }
})();
