(function () {
    'use strict';

    angular
        .module('mtvApp')
        .factory('ViewType', ViewType);

    ViewType.$inject = ['$resource'];

    function ViewType($resource) {
        function getValues() {
            return {
                'review-mode': $resource('api/review-mode/view-type', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                }),
                'trader-review-mode': $resource('api/review-mode/trader/view-type', {}, {
                    'query': {
                        method: 'GET',
                        isArray: true
                    }
                })
            };
        }

        return {
            getValues: getValues()
        };
    }
})();
