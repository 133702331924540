(function () {
    'use strict';

    var finraTradeHistory = {
        templateUrl: 'app/components/finra-trade-history/finra-trade-history.template.html',
        controller: FinraTradeHistoryController,
        bindings: {
            data: '<',
            onSelect: '&'
        }
    };

    angular
        .module('mtvApp')
        .component('finraTradeHistory', finraTradeHistory);

    FinraTradeHistoryController.$inject = ['$attrs'];

    function FinraTradeHistoryController($attrs) {
        var vm = this;
        vm.select = select;
        vm.shouldShowRecentTradesNote = shouldShowRecentTradesNote;

        var readOnly = $attrs.readOnly !== undefined;

        function select(item) {
            if (readOnly) {
                return;
            }
            vm.data.data.forEach(function(d) {
                d.active = false;
            });
            item.active = true;

            vm.onSelect({ item: item });
        }

        function shouldShowRecentTradesNote(obj) {
            var minTrades = obj.min || 0;
            var maxTrades = obj.max || 10000;

            return vm.data
                && vm.data.data
                && vm.data.data.length >= minTrades
                && vm.data.data.length <= maxTrades
                && !vm.data.showRecentTrades;
        }
    }
})();
