(function () {
    'use strict';

    var tradeHistory = {
        templateUrl: 'app/components/trade-history/trade-history.template.html',
        controller: TradeHistoryController,
        bindings: {
            data: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('tradeHistory', tradeHistory);

    TradeHistoryController.$inject = ['$attrs'];

    function TradeHistoryController($attrs) {
        var vm = this;
        vm.select = select;

        var readOnly = $attrs.readOnly !== undefined;

        function select(item) {
            if(readOnly) {
                return;
            }

            //TODO: change inner name
            vm.data.data.forEach(function(d) {
                d.active = false;
            });
            item.active = true;
        }
    }
})();
