(function () {
    'use strict';

    angular
        .module('mtvApp')
        .filter('minDenomination', minDenomination);

    minDenomination.$inject = ['$filter'];

    function minDenomination($filter) {
        return function(data) {
            if (!data) {
                return ''
            }

            var md = money(data['minDenomination']);
            var mdm = money(data['minDenominationMultiples']);

            if (mdm.length > 0) {
                return md + ' × ' + mdm
            }

            function money(value) {
                if (!value) {
                    return ''
                }

                return $filter('currency')(value['amount'] || '',  value['symbol']);
            }

            return md;
        }
    }

})();
