(function () {
    'use strict';

    var printPdf = {
        template: '<a href="" ng-click="$ctrl.print()" class="pdf-button">' +
            '<span class="glyphicon glyphicon-print"/> Print </a>' +
            '<span class="print-wait" id="printf"></span>',
        controller: PrintPdfController,
        bindings: {
            approvedHash: '<',
            searchParams: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('printPdf', printPdf);

    PrintPdfController.$inject = ['$http', '$uibModal', '$httpParamSerializer'];

    function PrintPdfController($http, $uibModal, $httpParamSerializer) {
        var vm = this;
        var dotsInterval;
        var url;

        vm.print = print;

        function print() {
            $http.get('api/shared-token').then(function (response) {
                if (vm.approvedHash) {
                    url = 'api/pdf-report/' + vm.approvedHash + '.pdf?token=' + response.data.id_token + '&actionType=PRINT';
                } else if (vm.searchParams) {
                    var data = $httpParamSerializer(vm.searchParams);
                    url = 'api/pdf-report/reports.pdf?' + data + '&token=' + response.data.id_token + '&actionType=PRINT';
                }
                printPdf(url);
            });
        }

        function printPdf(url) {
            if (isFirefox() || isEdge()) {
                printInFireFoxOrEdge(url);
            } else {
                printInOtherBrowsers(url);
            }
        }

        function isFirefox() {
            return (typeof InstallTrigger !== 'undefined');
        }

        function isEdge() {
            return navigator.appName == 'Netscape'
                && navigator.userAgent.toLowerCase().indexOf('edge') > -1;
        }

        function printInFireFoxOrEdge(url) {
            window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        }

        function printInOtherBrowsers(url) {
            var framePrint = '<iframe src="' + url + '" style="display:none;" ' +
                'name="print-frame" id="print-frame"></iframe>';
            var printFrame = angular.element("#print-frame");
            if (printFrame.length > 0) {
                printFrame.remove();
            }
            var modalInstance = $uibModal.open({
                template: '<div class="modal-header">' +
                    '<h4><i class="icon-print"></i> Preparing data for print' +
                    '<span id="print-dots"></span></h4></div>' +
                    '<div class="modal-footer"></div>',
                size: 'lg'
            });

            modalInstance.closed.then(function () {
                dotAnimationStop();
            });

            modalInstance.rendered.then(function () {
                angular.element('#printf').append(framePrint);
                dotAnimationStart('print-dots', 500);
                if (isIE()) {
                    printInInternetExplorer(printFrame, modalInstance);
                } else {
                    angular.element('#print-frame').on('load', function () {
                        dotAnimationStop();
                        modalInstance.close();
                        var frame = document.getElementById("print-frame");
                        frame.contentWindow.focus();
                        frame.contentWindow.print();
                    });
                }
            });
        }

        function isIE() {
            var ua = navigator.userAgent;
            var ieOldMatches = ua.match(/MSIE\s?(\d+).*/i);
            var ieNewRegExp = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})');
            return !!(ieOldMatches || ieNewRegExp.exec(ua));
        }

        function printInInternetExplorer(printFrame, modalInstance) {
            var inter = window.setInterval(function () {
                var printIeFrame = printFrame;
                var shouldEndEvent = false;
                try {
                    if (printIeFrame.contentWindow.document.readyState == 'complete') {
                        shouldEndEvent = true;
                    }
                } catch (e) {
                    shouldEndEvent = true;
                }
                if (shouldEndEvent) {
                    window.clearInterval(inter);
                    dotAnimationStop();
                    modalInstance.close();
                }
            }, 100);
        }

        function dotAnimationStart(spanIdToAnimate, interval) {
            var dotsGoingUp = true;
            dotsInterval = setInterval(function () {
                var wait = document.getElementById(spanIdToAnimate);
                if (dotsGoingUp)
                    wait.innerHTML += '.';
                else {
                    wait.innerHTML = wait.innerHTML.substring(1, wait.innerHTML.length);
                    if (wait.innerHTML === '')
                        dotsGoingUp = true;
                }
                if (wait.innerHTML.length > 3)
                    dotsGoingUp = false;
            }, interval);
        }

        function dotAnimationStop() {
            clearInterval(dotsInterval);
        }
    }
})();
