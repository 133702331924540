(function () {
    'use strict';

    angular
        .module('mtvApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('standard.trader-review-mode.date', {
            // TODO: wrap into filter object
            url: '/dates?:cusip:from:to:unreadOnlyFlag:outOfToleranceCheckFlag:bulkUploadFlag:commentsFlag:userCommentsFlag:correctionFlag:counterPartyFlags:finraFeedFlags:tradeSideFlags',
            views: {
                'trade-table-container': {
                    template: '<trade-table group-by="DATE"></trade-table>'
                }
            },
            params: {
                activePosition: undefined,
                innerPage: undefined,
                outerPage: undefined
            }

        });

        $stateProvider.state('standard.trader-review-mode.all-reports', {
            url: '/all-reports?:cusip:from:to:unreadOnlyFlag:outOfToleranceCheckFlag:bulkUploadFlag:commentsFlag:userCommentsFlag:correctionFlag:counterPartyFlags:finraFeedFlags:tradeSideFlags',
            views: {
                'trade-table-container': {
                    template: '<trade-table></trade-table>'
                }
            },
            params: {
                activePosition: undefined,
                innerPage: undefined,
                outerPage: undefined
            }
        });
    }
})();
