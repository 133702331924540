(function () {
    'use strict';

    var downloadPdf = {
        template: '<a href="" ng-click="$ctrl.download()" class="pdf-button">' +
        '<span class="glyphicon glyphicon-download"/> Download PDF </a>',
        controller: DownloadPdfController,
        bindings: {
            approvedHash: '<',
            searchParams: '<'
        }
    };

    angular
        .module('mtvApp')
        .component('downloadPdf', downloadPdf);

    DownloadPdfController.$inject = ['$http', '$httpParamSerializer'];

    function DownloadPdfController($http, $httpParamSerializer) {
        var vm = this;
        var url;

        vm.download = download;

        function download() {
            $http.get('api/shared-token').then(function (response) {
                if (vm.approvedHash) {
                    url = 'api/pdf-report/' + vm.approvedHash + '.pdf?token=' + response.data.id_token + '&actionType=DOWNLOAD';
                } else if (vm.searchParams) {
                    var data = $httpParamSerializer(vm.searchParams);
                    url = 'api/pdf-report/reports.pdf?' + data + '&token=' + response.data.id_token + '&actionType=DOWNLOAD';
                }
                downloadPdf(url);
            });
        }

        function downloadPdf(url) {
            var openMethod = '_blank';
            if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1
                || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {

                var link = window.document.createElement('a');
                link.href = url;

                if (link.download !== undefined) {
                    link.download = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));
                }

                if (window.document.createEvent) {
                    var e = window.document.createEvent('MouseEvents');
                    e.initEvent('click', true, true);
                    link.dispatchEvent(e);
                    return true;
                }
                openMethod = '_self';
            }
            window.open(url, openMethod);
        }
    }
})();
